import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
export const sentryInit = () => {
    Sentry.init({
        Vue,
        dsn: 'https://4968df55df944059a3fc9c62dffdf65b@o778022.ingest.sentry.io/5797887',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        release: 'tera-lxs-v2@' + process.env.APP_VERSION,
        logErrors: true
    });
};
