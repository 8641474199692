var authConfig = {}

if (
  window.location.host.includes('.qa.') ||
  window.location.host.includes('.qa-1.') ||
  window.location.host.includes('.qa-2.') ||
  window.location.host.includes('.qa-3.') ||
  window.location.host.includes('.qa-4.') ||
  window.location.host.includes('localhost')
) {
  authConfig = {
    apiKey: 'AIzaSyBCNNrV5yj9N53Jhwbnqw1QztjxiOouv60',
    authDomain: 'auth.qa.somostera.com',
    databaseUrl: 'https://tera-platform-qa.firebaseio.com',
    projectId: 'tera-platform-qa'
  }
} else {
  authConfig = {
    apiKey: 'AIzaSyAr52KsWi5N9jNxCIzxXit0JXBtC-OCRYM',
    authDomain: 'auth.somostera.com',
    databaseUrl: 'https://tera-platform.firebaseio.com',
    projectId: 'tera-platform'
  }
}

// COMMENT AND DISCOMMENT THE BLOCKS TO TOGGLE MIRROR PRODUCTION ENV IN YOUR TEST ENV
// authConfig = {
//   apiKey: 'AIzaSyAr52KsWi5N9jNxCIzxXit0JXBtC-OCRYM',
//   authDomain: 'auth.somostera.com',
//   databaseUrl: 'https://tera-platform.firebaseio.com',
//   projectId: 'tera-platform'
// }

export default authConfig
