import { db } from '@/core/services/firebase'

const PEOPLE_COLLECTION_NAME = 'people'
const peopleDb = db.collection(PEOPLE_COLLECTION_NAME)

const state = {
  currentJourneyArticles: [],
  currentArticleId: '',
  currentChapterInteractions: []
}

const getters = {
  currentChapterIndex: state => {
    const { currentChapterInteractions } = state
    return currentChapterInteractions.length > 0 ? currentChapterInteractions.findIndex(i => i.currentChapter) : 0
  },
  currentJourneyArticle: state => {
    const { currentJourneyArticles, currentArticleId } = state
    return currentJourneyArticles && currentArticleId
      ? currentJourneyArticles.find(c => c.id === state.currentArticleId)
      : null
  },
  currentChapterIsLastChapter: (state, getters) => {
    return getters.currentChapterIndex === state.currentChapterInteractions.length - 1
  },
  getArticleById: (state) => (id) => {
    return state.currentJourneyArticles.find(article => article.id === id)
  }
}

const actions = {
  async setCurrentJourneyArticles({ commit, state, dispatch }, journeyBlocks) {
    const journeyArticles = journeyBlocks.filter(j => j.type === 'article')
    const articles = []

    for (const journeyArticle of journeyArticles) {
      const docSnapshot = await journeyArticle.articlesRef.get()
      if (docSnapshot.exists) {
        articles.push({ id: docSnapshot.id, ...docSnapshot.data() })
      }
    }

    commit('setCurrentJourneyArticles', articles)

    if (state.currentArticleId) {
      dispatch('setCurrentArticleInteractions')
    }
  },
  async getNumberOfChaptersDone({ rootState }, articleId) {
    const { person } = rootState.account

    const querySnapshot = await peopleDb
      .doc(person.id)
      .collection('people_article_interaction')
      .doc(articleId)
      .get()

    if (!querySnapshot.exists) {
      return 0
    }

    const currentChapterInteractions = querySnapshot.data().chapterInteractions

    let numberOfChaptersDone = 0

    currentChapterInteractions.forEach(chapter => {
      if (chapter.status === 'done') {
        numberOfChaptersDone += 1
      }
    })

    return numberOfChaptersDone
  },
  async updateCurrentArticleInteractions({ commit, rootState, state }, chapterInteractions) {
    const { person } = rootState.account

    if (person && person.id && state.currentArticleId) {
      commit('setPeopleArticleInteraction', chapterInteractions)

      await peopleDb
        .doc(person.id)
        .collection('people_article_interaction')
        .doc(state.currentArticleId)
        .update({ chapterInteractions: chapterInteractions })
    }
  },
  async setCurrentArticleInteractions({ commit, rootState, state, getters }) {
    const { person } = rootState.account

    if (person && person.id) {
      const article = getters.currentJourneyArticle
      if (!article) {
        return
      }
      const interactionDocSnapshot = await peopleDb
        .doc(person.id)
        .collection('people_article_interaction')
        .doc(article.id)
        .get()

      if (interactionDocSnapshot.exists) {
        commit('setPeopleArticleInteraction', interactionDocSnapshot.data().chapterInteractions)
      } else {
        const newPeopleArticleInteraction = article.chapters.map((chapter, index) => {
          const status = index === 0 ? 'doing' : 'unseen'
          const currentChapter = index === 0
          const chapterIndex = index
          const chapterInteraction = {
            status,
            currentChapter,
            chapterIndex
          }

          return chapterInteraction
        })

        await peopleDb
          .doc(person.id)
          .collection('people_article_interaction')
          .doc(state.currentArticleId)
          .set({ chapterInteractions: newPeopleArticleInteraction })

        commit('setPeopleArticleInteraction', newPeopleArticleInteraction)
      }
    }
  },
  async setCurrentArticleId({ commit, state, dispatch }, articleId) {
    commit('setCurrentArticleId', articleId)
    if (state.currentJourneyArticles) {
      dispatch('setCurrentArticleInteractions')
    }
  },
  async changeInteractionCurrentChapterByIndex({ commit, rootState }) {
    const { person } = rootState.account
    if (state.currentJourneyArticles) {
      const updatedInteractions = state.currentChapterInteractions.map(c => {
        return {
          chapterIndex: c.chapterIndex,
          currentChapter: false,
          status: c.status
        }
      })

      await peopleDb
        .doc(person.id)
        .collection('people_article_interaction')
        .doc(state.currentArticleId)
        .update({ chapterInteractions: updatedInteractions })

      commit('setPeopleArticleInteraction', updatedInteractions)
    }
  },
  async articleToNextChapter({ getters, state, commit, dispatch }) {
    const currentChapter = getters.currentChapterIndex

    const isLastChapter = currentChapter === state.currentChapterInteractions.length - 1

    if (isLastChapter) {
      commit('changeArticleInteraction', {
        interactionIndex: currentChapter,
        currentChapter: false,
        status: 'done'
      })

      commit('changeArticleInteraction', {
        interactionIndex: 0,
        currentChapter: true
      })
    } else {
      commit('changeArticleInteraction', {
        interactionIndex: currentChapter,
        currentChapter: false,
        status: 'done'
      })

      commit('changeArticleInteraction', {
        interactionIndex: currentChapter + 1,
        currentChapter: true,
        status: 'doing'
      })
    }

    window.scrollTo(0, 0)

    dispatch('updateCurrentArticleInteractions', state.currentChapterInteractions)
  },
  async setArticleCurrentChapterByIndex({ dispatch, state }, index) {
    const { currentChapterInteractions } = state

    if (currentChapterInteractions[index].status !== 'unseen') {
      var nextInteractions = currentChapterInteractions.map(i => {
        return {
          status: i.status,
          currentChapter: false,
          chapterIndex: i.chapterIndex
        }
      })

      nextInteractions[index].currentChapter = true

      window.scrollTo(0, 0)

      dispatch('updateCurrentArticleInteractions', nextInteractions)
    }
  }
}

const mutations = {
  setCurrentJourneyArticles: (state, articles) => {
    state.currentJourneyArticles = articles
  },
  setCurrentArticleId: (state, articleId) => {
    state.currentArticleId = articleId
  },
  setCurrentChapter: (state, currentChapterIndex) => {
    const lastItemIndex = state.currentChapterInteractions.length - 1

    if (lastItemIndex >= currentChapterIndex) {
      throw {
        error: 'article/store/setCurrentChapter/chapter-does-not-exists',
        message: 'O artigo não contém esse capitúlo'
      }
    }
    const nextCurrentChapterStatus = state.currentChapterInteractions[currentChapterIndex].status

    if (nextCurrentChapterStatus === 'unseen') {
      throw {
        error: 'article/store/setCurrentChapter/chapter-never-seen',
        message: 'O artigo ainda não foi visto, termine os capítulos anteriores antes'
      }
    } else {
      state.currentChapterInteractions.forEach(i => {
        i.currentChapter = false
      })

      state.currentChapterInteractions[currentChapterIndex].status = 'doing'

      state.currentChapterInteractions[currentChapterIndex].currentChapter = true
    }
  },
  setPeopleArticleInteraction: (state, peopleArticleInteraction) => {
    state.currentChapterInteractions = peopleArticleInteraction
  },
  changeArticleInteraction: (state, { interactionIndex, currentChapter, status }) => {
    state.currentChapterInteractions[interactionIndex].currentChapter = currentChapter

    if (state.currentChapterInteractions[interactionIndex].status !== 'done') {
      state.currentChapterInteractions[interactionIndex].status = status
    }
  }
}

export const articlesWatchers = {
  journeyBlocksLoaded(store) {
    return {
      state: state => state.journeys.currentJourneyBlocks,
      watcher: currentJourneyBlocks => {
        if (currentJourneyBlocks && currentJourneyBlocks.length) {
          store.dispatch('articles/setCurrentJourneyArticles', currentJourneyBlocks)
        } else {
          store.dispatch('journeys/toggleLoading', false)
        }
      }
    }
  }
}

export const articles = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
