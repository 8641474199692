<template>
  <div v-if="currentJourneyBlock" class="class">
    <div class="header">
      <base-header type="header" class="journey-header">
        <template v-slot:default>
          <heading-with-before color="white" :before-color-variation="true">
            {{ blockName }}
          </heading-with-before>
        </template>
        <template v-slot:footer>
          <heading tag="h4" color="white">{{ dateFormatted }}</heading>
          <heading
            v-if="
              currentJourneyBlock.liveUrl &&
                !currentUserJourney.teraId.includes('EV_REM')
            "
            tag="h4"
            text-transform="none"
            font-weight="normal"
            color="white"
          >
            Aula Online:
            <a :href="currentJourneyBlock.liveUrl" target="_blank">
              {{ currentJourneyBlock.liveUrl }}
            </a>
          </heading>
        </template>
      </base-header>
    </div>
    <class-tab-menu
      :has-content="showContentTab"
      :active-tab="activeTab"
      @input="activeTab = $event"
    >
    </class-tab-menu>
    <transition name="fade">
      <main-container v-if="activeTab === 'about'" :key="`about-tab`">
        <class-live-embed
          v-if="
            currentUserJourney.teraId.includes('EV_REM') &&
              currentJourneyBlock.startTime &&
              currentJourneyBlock.endTime
          "
          :src="currentJourneyBlock.liveUrl"
          :journey-icon="currentUserJourney.courseInfo.iconUrl"
          :start-time="currentJourneyBlock.startTime"
          :end-time="currentJourneyBlock.endTime"
          :question-src="currentJourneyBlock.liveQuestionLink"
        ></class-live-embed>
        <medium-gradient-card
          v-if="classIsLive && !currentUserJourney.teraId.includes('EV_REM')"
          class="class-reminder"
        >
          <template v-slot:content>
            <heading tag="h3">Sua aula já começou!</heading>
            <!-- Clique no botão para entrar na aula -->
          </template>
          <template v-slot:right>
            <base-button
              :href="currentJourneyBlock.liveUrl"
              class="reminder-enter-button"
            >
              Entrar na aula
            </base-button>
          </template>
        </medium-gradient-card>
        <LargeGradientBorderCard
          v-if="
            currentExpert &&
              currentJourneyBlock.expertStatus &&
              currentJourneyBlock.expertStatus === 'aceito'
          "
          class="expert-presentation-container"
        >
          <template v-slot:left>
            <img
              class="expert-photo"
              :src="currentExpert.pictureUrl"
              :alt="`Expert: ${currentExpert.name}`"
            />
          </template>
          <template v-slot:content>
            <heading tag="h3" font-style="normal" font-weight="light">
              {{ currentExpert.name }}
            </heading>
            <heading
              class="role-and-company"
              tag="h4"
              font-style="normal"
              font-weight="light"
              text-transform="none"
              color="gray-80"
            >
              {{ currentExpert.jobTitle }} .
              {{ currentExpert.company }}
            </heading>
            <paragraph class="description -font-weight-normal">
              {{ currentExpert.description }}
            </paragraph>
            <a
              v-if="currentExpert.profileUrl"
              :href="currentExpert.profileUrl"
              target="_blank"
              class="linkedIn"
              @click="clickExpert"
            ><icon name="ic_linkedin" fill="color_white" /></a>
          </template>
        </LargeGradientBorderCard>
        <div v-if="currentJourneyBlock.description" class="description">
          <heading tag="h4">Sobre a aula</heading>
          <paragraph>{{ currentJourneyBlock.description }}</paragraph>
        </div>
        <div v-if="showDownloadableContents" class="downloadable-contents">
          <div
            v-if="userIsFacilitatorOrCoordinator"
            class="upload-downloadable-content"
          >
            <label for="uploadmaterial">
              <input
                id="uploadmaterial"
                type="file"
                name="uploadmaterial"
                accept="image/jpeg,image/png,application/pdf"
                :disabled="isUploadingMaterial"
                @change="uploadDownloadableContents($event)"
              />
            </label>
          </div>
          <a
            v-for="(beforeClass, i) in currentJourneyBlock.downloads"
            :key="'downloads_' + i"
            class="styleless-link download-content-card"
            target="_blank"
            :href="beforeClass.url"
            @click="downloadClick(beforeClass)"
          >
            <small-rectangle-base-card>
              <template v-slot:content>
                <div class="heading">
                  <a
                    v-if="userIsFacilitatorOrCoordinator"
                    class="-delete"
                    href="#"
                    @click="removeDownloadableSpecificContent(beforeClass, i)"
                  >X</a>
                  <heading tag="h4" color="white" font-weight="normal">{{
                    beforeClass.name
                  }}</heading>
                </div>
              </template>
              <template v-slot:footer>
                <p class="-font-gray-60 -font-rubik">
                  {{ beforeClass.sizeInMb + 'mb' }}
                </p>
              </template>
              <template v-slot:right>
                <icon name="ic_download_white" fill="color_white" />
              </template>
            </small-rectangle-base-card>
          </a>
          <a
            v-for="(beforeClass, i) in currentJourneyBlock.fileBeforeClass"
            :key="'fileBeforeClass_' + i"
            class="styleless-link download-content-card"
            target="_blank"
            :href="beforeClass.url"
            @click="downloadClick(beforeClass)"
          >
            <small-rectangle-base-card>
              <template v-slot:content>
                <div class="heading">
                  <a
                    v-if="userIsFacilitatorOrCoordinator"
                    class="-delete"
                    href="#"
                    @click="removeDownloadableSpecificContent(beforeClass, i)"
                  >
                    X
                  </a>
                  <heading tag="h4" color="white" font-weight="normal">{{
                    beforeClass.name
                  }}</heading>
                </div>
              </template>
              <template v-slot:footer>
                <p class="-font-gray-60 -font-rubik">
                  {{ beforeClass.sizeInMb + 'mb' }}
                </p>
              </template>
              <template v-slot:right>
                <icon name="ic_download_white" fill="color_white" />
              </template>
            </small-rectangle-base-card>
          </a>
        </div>
        <div
          v-if="
            currentJourneyBlock.outcomes &&
              currentJourneyBlock.outcomes.length > 0
          "
          class="objectives"
        >
          <heading tag="h4">Objetivos</heading>
          <ul class="list">
            <li
              v-for="(outcome, i) in currentJourneyBlock.outcomes"
              v-if="outcome"
              :key="'outcomes_' + i"
              class="item"
            >
              {{ outcome }}
            </li>
          </ul>
        </div>
      </main-container>
      <main-container v-else :key="`content-tab`">
        <div
          v-if="currentJourneyBlock.contentBeforeClass.length > 0"
          class="content-before-class"
        >
          <heading tag="h4">Prepare-se para a aula</heading>
          <div class="content-container">
            <div class="card-container" @click="clickAfterClass">
              <material-card
                v-for="(
                  content, i
                ) in currentJourneyBlock.contentBeforeClass.filter(
                  (c) => c.type === 'originals'
                )"
                :key="'contentBeforeClass_originals_' + i"
                :title="getOriginalsTitleForBeforeClassContent(content)"
                :subtitle="`Tera Originals`"
                :route="{
                  name: 'Originals',
                  params: {
                    originalsUrl: getOriginalsURLForBeforeClassContent(content)
                  }
                }"
              ></material-card>
              <material-card
                v-for="(
                  content, i
                ) in currentJourneyBlock.contentBeforeClass.filter(
                  (c) => c.type !== 'originals'
                )"
                :key="'contentBeforeClass_' + i"
                :title="content.title"
                :subtitle="`${
                  content.type[0].toUpperCase() + content.type.substring(1)
                } • ${content.expectedMinutes}min`"
                :route="{ href: content.url, target: '_blank' }"
              ></material-card>
            </div>
          </div>
        </div>
        <div
          v-if="currentJourneyBlock.contentAfterClass.length > 0"
          class="content-before-class"
        >
          <heading tag="h4">Entenda mais</heading>
          <div class="content-container">
            <div class="card-container" @click="clickAfterClass">
              <material-card
                title="Biblioteca de Links para Aprofundamento"
                subtitle="Link externo"
                :route="{
                  href: currentJourneyBlock.contentAfterClass,
                  target: '_blank'
                }"
              ></material-card>
            </div>
          </div>
        </div>
      </main-container>
    </transition>
  </div>
</template>

<script>
import MainContainer from '@/core/components/MainContainer'
import ClassTabMenu from '@/modules/Journeys/components/ClassTabMenu'
import MaterialCard from '@/modules/Journeys/components/MaterialCard'
import ClassLiveEmbed from '@/modules/Journeys/components/ClassLiveEmbed'

import { mapGetters, mapActions } from 'vuex'

const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

export default {
  name: 'Class',
  components: {
    MainContainer,
    ClassTabMenu,
    MaterialCard,
    ClassLiveEmbed
  },
  props: {
    teraId: {
      type: String,
      required: true
    },
    journeyBlockId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'about',
      classSeen: false,
      isUploadingMaterial: false
    }
  },
  computed: {
    userIsFacilitatorOrCoordinator() {
      if (!this.currentJourneyPeople) {
        return false
      }

      var personRoles = this.currentJourneyPeople.type || []
      var roleIsFacilitatorOrCoordinator =
        personRoles.includes('facilitator') ||
        personRoles.includes('coordinator')

      return roleIsFacilitatorOrCoordinator
    },
    showDownloadableContents() {
      const { currentJourneyBlock } = this
      return (
        (currentJourneyBlock.fileBeforeClass &&
          currentJourneyBlock.fileBeforeClass.length > 0) ||
        (currentJourneyBlock.downloads &&
          currentJourneyBlock.downloads.length > 0) ||
        this.userIsFacilitatorOrCoordinator
      )
    },
    blockName() {
      return this.currentJourneyBlock.name
    },
    dateFormatted() {
      if (!this.currentJourneyBlock) {
        return ''
      }

      var { startTime, endTime } = this.currentJourneyBlock
      if (!startTime) {
        return ''
      }

      var datePadded = String(startTime.getDate()).padStart(2, '0')
      var monthName = monthNames[startTime.getMonth()]
      var fullYear = startTime.getFullYear()
      var startHours = startTime.getUTCHours()
      var startMinutes = String(startTime.getUTCMinutes()).padStart(2, '0')
      var startTimeFormatted =
        startMinutes === '00'
          ? `${startHours}h`
          : `${startHours}h${startMinutes}`

      var endHours = endTime.getUTCHours()
      var endMinutes = String(endTime.getUTCMinutes()).padStart(2, '0')
      var endTimeFormatted =
        endMinutes === '00' ? `${endHours}h` : `${endHours}h${endMinutes}`

      return `${datePadded} de ${monthName} de ${fullYear} ${startTimeFormatted} - ${endTimeFormatted}`
    },
    childRouteParams() {
      return { teraId: this.teraId }
    },
    currentOriginals() {
      if (!this.currentJourneyBlocks) {
        return []
      }

      const moduleOriginals = this.currentJourneyBlocks.filter(
        (jb) => jb.type === 'originals' && jb.moduleIndex === this.moduleIndex
      )

      return moduleOriginals.map((mo) => {
        // originalsDocPath: 'originals/hashId'
        const originalsId = mo.originalsDocPath.split('/')[1]
        return this.currentUserOriginals.find((o) => o.id === originalsId)
      })
    },
    classIsLive() {
      if (
        this.currentJourneyBlock.startTime &&
        this.currentJourneyBlock.endTime
      ) {
        const milisecondsOffset = new Date().getTimezoneOffset() * 60 * 1000

        const startTimeWithoutTimezone = new Date(
          this.currentJourneyBlock.startTime
        )
        startTimeWithoutTimezone.setTime(
          startTimeWithoutTimezone.getTime() + milisecondsOffset
        )

        const endTimeWithoutTimezone = new Date(
          this.currentJourneyBlock.endTime
        )
        endTimeWithoutTimezone.setTime(
          endTimeWithoutTimezone.getTime() + milisecondsOffset
        )

        return (
          startTimeWithoutTimezone <= new Date() &&
          endTimeWithoutTimezone >= new Date()
        )
      }

      return false
    },
    showContentTab() {
      const { contentBeforeClass, contentAfterClass } = this.currentJourneyBlock
      return contentBeforeClass.length > 0 || contentAfterClass !== ''
    },
    ...mapGetters('journeys', [
      'currentUserJourney',
      'currentJourneyBlock',
      'currentJourneyMoment',
      'currentJourneyPeople',
      'journeyBlocksHasUpdates',
      'currentExpert',
      'currentPlace',
      'currentUserOriginals'
    ]),
    ...mapGetters('account', ['user', 'person'])
  },
  watch: {
    currentJourneyBlock: function () {
      if (this.classSeen) return
      this.$amplitude.getInstance().logEvent('class seen', {
        name: this.currentJourneyBlock.name,
        order: this.currentJourneyBlock.index,
        'tera id': this.teraId,
        expert: this.currentExpert ? this.currentExpert.name : '',
        place: this.currentPlace ? this.currentPlace.name : '',
        date: this.currentJourneyBlock.date,
        'start time': this.currentJourneyBlock.startTime,
        'end time': this.currentJourneyBlock.endTime,
        user: this.user ? this.user.uid : '',
        moment: this.currentJourneyMoment,
        value: 'high'
      })
      this.classSeen = true
    }
  },
  beforeMount() {
    this.setCurrentTeraId(this.teraId)
    this.setCurrentJourneyBlockId(this.journeyBlockId)
  },
  mounted() {
    const id = new this.$amplitude.Identify()
    id.set('current moment', this.currentJourneyMoment)
    this.$amplitude.getInstance().identify(id)

    const {
      journeys,
      modules,
      journeyClass
    } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, journeyClass]

    this.setRouteBreadcrumb(breadcrumb)
  },
  methods: {
    getOriginalsTitleForBeforeClassContent(content) {
      if (!this.currentUserOriginals) return
      const originals = this.currentUserOriginals.find(
        (o) => o.id === content.refId
      )
      return originals.title
    },
    getOriginalsURLForBeforeClassContent(content) {
      if (!this.currentUserOriginals) return
      const originals = this.currentUserOriginals.find(
        (o) => o.id === content.refId
      )
      return originals.url
    },
    clickExpert() {
      this.$ga.event(
        'Class',
        'expert click',
        `LinkedIn for ${this.currentExpert.name}`
      )
      this.$amplitude.getInstance().logEvent('class linkedin clicked', {
        name: this.currentJourneyBlock.name,
        order: this.currentJourneyBlock.index,
        'tera id': this.teraId,
        expert: this.currentExpert ? this.currentExpert.name : '',
        place: this.currentPlace ? this.currentPlace.name : '',
        date: this.currentJourneyBlock.date,
        'start time': this.currentJourneyBlock.startTime,
        'end time': this.currentJourneyBlock.endTime,
        user: this.user ? this.user.uid : '',
        moment: this.currentJourneyMoment,
        value: 'high'
      })
    },
    clickAfterClass() {
      this.$ga.event('Class', 'after class click', `After class ${this.title}`)
      this.$amplitude.getInstance().logEvent('class after content clicked', {
        name: this.currentJourneyBlock.name,
        order: this.currentJourneyBlock.index,
        'tera id': this.teraId,
        expert: this.currentExpert ? this.currentExpert.name : null,
        place: this.currentPlace ? this.currentPlace.name : null,
        date: this.currentJourneyBlock.date,
        'start time': this.currentJourneyBlock.startTime,
        'end time': this.currentJourneyBlock.endTime,
        user: this.user ? this.user.uid : '',
        moment: this.currentJourneyMoment,
        value: 'high'
      })
    },
    downloadClick(beforeClass) {
      this.$ga.event('Class', 'download click', beforeClass.name)
      this.$amplitude.getInstance().logEvent('class download clicked', {
        name: this.currentJourneyBlock.name,
        order: this.currentJourneyBlock.index,
        'tera id': this.currentJourneyBlock.teraId,
        expert: this.currentExpert ? this.currentExpert.name : '',
        place: this.currentPlace ? this.currentPlace.name : '',
        date: this.currentJourneyBlock.date,
        'start time': this.currentJourneyBlock.startTime,
        'end time': this.currentJourneyBlock.endTime,
        user: this.user.uid,
        'download name': beforeClass.name,
        'download type': beforeClass.type,
        'download size': beforeClass.sizeInMb,
        moment: this.currentJourneyMoment,
        value: 'high'
      })
    },
    uploadDownloadableContents(evt) {
      if (!this.userIsFacilitatorOrCoordinator) return

      const file = evt.target.files ? evt.target.files[0] : undefined

      if (file.name.indexOf('#') > -1) {
        alert("Por favor, não utilize o caractere '#' no nome do arquivo.")
        evt.target.value = ''
        return
      }

      var name = prompt('Qual o nome do material?')

      if (name) {
        this.isUploadingMaterial = true
        try {
          this.uploadDownloadableContent({ file, name })
          this.isUploadingMaterial = false
        } catch (error) {
          console.log(error)
        }
      } else {
        alert('Por favor, digite o nome do material para download')
      }
    },
    removeDownloadableSpecificContent(beforeClass, index) {
      if (!this.userIsFacilitatorOrCoordinator) return

      var mustDelete = confirm(
        `Você tem certeza que deseja excluir o arquivo "${beforeClass.name}"?`
      )

      if (mustDelete) {
        try {
          this.deleteSpecificDownloadableContent(
            this.currentJourneyBlock.downloads[index]
          )
        } catch (error) {
          console.log(error)
        }
      }
    },
    ...mapActions('journeys', [
      'setCurrentTeraId',
      'setCurrentJourneyBlockId',
      'updateJourneyBlocksWithStream',
      'uploadDownloadableContent',
      'deleteSpecificDownloadableContent'
    ]),
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: right 0.2s, opacity 0.3s;
}
.fade-leave-active {
  transition: right 0.2s, opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  position: absolute;
  right: 100%;
}

.class {
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  .main-container {
    width: $container-m;
    margin: $space-l auto 0;
    box-sizing: border-box;

    @include breakpoint-small {
      margin: $space-s auto 0;
      width: 100%;
    }

    .content-before-class {
      & + .content-before-class {
        margin-top: $space-l;
      }
    }

    .content-container {
      margin-top: $space-s;

      > .card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > * {
          margin-bottom: $space-s;
        }
      }
    }
  }

  .class-reminder {
    padding: 24px $space-s;

    @include breakpoint-small {
      align-items: center;

      ::v-deep > .content {
        margin-top: 0 !important;
        margin-bottom: $space-xs;
      }
    }

    .reminder-enter-button {
      height: auto;
      ::v-deep {
        z-index: unset;
      }
    }

    @include breakpoint-small {
      ::v-deep .content {
        width: fit-content;
        text-align: center;
        margin-top: $space-xs;
      }
    }
  }

  .expert-presentation-container {
    margin-top: $space-l;
    margin-bottom: $space-xl;

    ::v-deep .content {
      position: relative;
      padding: $space-s;

      > .role-and-company {
        margin-top: $space-xs / 2;
      }

      > .description {
        margin-top: $space-s;
      }
    }

    .expert-photo {
      width: 100%;
      object-fit: cover;
    }

    .linkedIn {
      position: absolute;
      top: $space-s;
      right: $space-s;
    }
  }

  .upload-downloadable-content {
    width: 100%;
    max-width: 368px;
    height: 160px;
    padding-bottom: $space-xs;

    > label {
      width: 50%;
      cursor: pointer;

      &:hover:after {
        background-color: $color-black;
        transition: all 0.4s;
      }

      &::after {
        content: 'Adicionar arquivo para download';
        text-align: center;
        max-width: 368px;
        width: 100%;
        line-height: 20px;
        height: 160px;
        font-size: 17px;
        background-color: #666;
        color: #fff;
        font-family: 'Rubik';
        text-transform: uppercase;
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        padding: 30px;
        transition: all 0.4s;
      }

      &.-uploading {
        background-color: #333;
        display: inline-block;
        padding: 25px 110px;
        &:after {
          content: ' ';
          display: block;
          width: 46px;
          height: 46px;
          margin: 1px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #fff transparent #fff transparent;
          animation: lxs-uploading-material 1.2s linear infinite;
        }
        @keyframes lxs-uploading-material {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      [type='file'] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
      }
    }
  }

  .downloadable-contents {
    margin-top: $space-xl;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .download-content-card {
      width: 100%;
      max-width: 368px;
      max-height: 160px;

      @include breakpoint-medium {
        margin-bottom: $space-s;
      }
      @include breakpoint-small {
        margin-bottom: $space-s;
      }
    }
  }
}

.objectives {
  margin-top: $space-xl;
}

::v-deep h4.heading + .paragraph,
h4.heading + .list {
  margin-top: $space-xs;
}

::v-deep .header .footer-container h4 a {
  word-break: break-all;
}

::v-deep .small-rectangle-base-card {
  height: 100% !important;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding: $space-xs;

    > .heading {
      width: 100%;
      max-width: 256px;

      > .-delete {
        font-family: $font-rubik;
        color: $color-white;
        float: right;

        &:hover {
          color: $color-gray-60;
        }
      }

      > h4 {
        max-width: 288px;
        position: initial !important;
        display: flex;
        align-items: flex-start;
        box-sizing: border-box;
      }
    }

    > .footer {
      font-family: $font-rubik;
      position: initial !important;
      bottom: initial !important;
      min-height: initial !important;
      margin-top: auto;
    }
  }

  .aside-right {
    position: initial !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;

    > span {
      position: initial !important;
    }
  }
}

.styleless-link {
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
}

.list {
  padding-left: $space-s;

  > .item {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

::v-deep .main-container {
  padding-bottom: 120px;
}

a {
  color: $color-blue-primary;
  text-decoration: none;

  &:visited {
    color: $color-blue-primary;
  }
}
</style>
