<template>
  <transition name="fade-to-login">
    <div v-if="!isWaitingLogin" ref="background" key="login" class="login-background">
      <transition name="fade">
        <div v-if="animationFrame==1" key="1" class="frame frame-1"></div>
        <div v-if="animationFrame==2" key="2" class="frame frame-2"></div>
        <div v-if="animationFrame==3" key="3" class="frame frame-3"></div>
        <div v-if="animationFrame==4" key="4" class="frame frame-4"></div>
        <div v-if="animationFrame==5" key="5" class="frame frame-5"></div>
        <div v-if="animationFrame==6" key="6" class="frame frame-6"></div>
        <div v-if="animationFrame==7" key="7" class="frame frame-7"></div>
        <div v-if="animationFrame==8" key="8" class="frame frame-8"></div>
      </transition>
      <div class="login-box">
        <div v-if="loginInitialView" class="email-step-one">
          <div class="logo">
            <img src="@/assets/lxs_logo.svg" />
          </div>
          <form @submit.prevent="loginWithEmail">
            <div class="login-email-input">
              <base-text-input v-model="email" autocomplete="email" :required="true" label="E-mail" placeholder="Digite seu e-mail" type="email"></base-text-input>
            </div>
            <div class="login-email-next">
              <black-button v-if="!loginWithEmailIsLoading" submit="submit" type="button" name="button-email">Avançar</black-button>
              <base-button v-else type="button" name="button" state="disabled" color="-background-gray-80">Aguarde...</base-button>
            </div>
          </form>
          <hr />
          <div class="login-google">
            <p>
              Experimente também:
            </p>
            <login-provider-button icon="ic_google_logo" type="button" name="button-google" @click="loginWithGoogle">Entrar com Google</login-provider-button>
          </div>
        </div>
        <div v-if="loginWithEmailStepTwo" class="email-step-two">
          <div class="logo">
            <img src="@/assets/lxs_email_login.svg" />
          </div>
          <p>
            Enviamos um link de acesso para:<br /><span class="-font-color-blue">{{ email }}</span>
          </p>
          <p class="-normal">
            Basta acessar sua caixa de entrada e clicar no link do e-mail.
          </p>
          <p class="-normal">
            Você será enviada(o) diretamente para dentro do LXS.
          </p>
          <hr />
          <a class="link email-help" @click="showLoginWithEmailHelp">Não recebi o e-mail</a>
          <a class="link back-step-one" @click="backToLogin">Voltar</a>
        </div>
        <div v-if="loginWithEmailHelp" class="email-problems">
          <p>
            Não recebeu o e-mail de acesso?
          </p>
          <p class="help-text">
            Tente essas dicas rápidas:
            <ul>
              <li>
                Lembre-se de utilizar o mesmo e-mail que você realizou sua inscrição na Tera.
              </li>
              <li>
                Verifique se o e-mail está na sua caixa de spam ou foi filtrado.
              </li>
              <li>
                Verifique sua conexão.
              </li>
              <li>
                Verifique que o e-mail "{{ email }}" não tenha erros de digitação.
              </li>
              <li>
                Se você está usando um e-mail corporativo, algumas políticas de segurança da sua empresa podem ter bloqueado seu recebimento. Tente utilizar seu e-mail pessoal.
              </li>
              <li>
                Verifique que sua caixa de entrada não esteja cheia.
              </li>
              <li>
                Caso você esteja utilizando uma VPN, algumas restrições podem ter impedido o seu acesso. Tente utilizar uma conexão diferente.
              </li>
            </ul>
            Caso as dicas acima não funcionem, você pode tentar reenviar o e-mail. Isto irá desativar o link enviado para o e-mail anterior.
          </p>
          <blue-button v-if="!loginWithEmailIsLoading" @click="backToLogin">Tentar novamente</blue-button>
          <a class="link" @click="showLoginWithEmailStepTwo">Voltar</a>
        </div>
        <small v-if="loginWithEmailStepTwo" class="bottom-help-text">Para sua segurança, o login da Tera não utiliza senhas. Dessa forma, você não precisa se lembrar e não arrisca utilizar a mesma senha em sites diferentes.</small>
        <p v-if="!loginWithEmailStepTwo" id="terms-and-privacy" class="terms-of-use bottom-help-text">
          <a href="https://somostera.com/termos-de-uso" target="blank">Termos de Uso</a> 🞄 <a href="https://somostera.com/privacidade" target="blank">Política de Privacidade</a>
        </p>
        <p v-if="!loginWithEmailStepTwo" class="terms-of-use bottom-help-text">
          Feito com amor pela Tera
        </p>
      </div>
    </div>
    <div v-else ref="background" key="loading" class="loading-background">
      <loading></loading>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { firebase } from '@/core/services/firebase'
import { journeyRouteContext } from '@/modules/Journeys/routes'

import Loading from '@/core/components/Loading.vue'

export default {
  name: 'Login',
  components: {
    Loading
  },
  data() {
    return {
      email: '',
      loginWithEmailStepTwo: false,
      loginWithEmailIsLoading: false,
      loginWithEmailHelp: false,
      animationFrame: 1,
      animationIntervalRef: null,
      isWaitingLoginWithGoogle: false
    }
  },
  computed: {
    loginInitialView() {
      return !(this.loginWithEmailStepTwo || this.loginWithEmailHelp)
    },
    ...mapGetters('account', ['person', 'isWaitingLogin', 'isLoggedIn'])
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.redirectToJourneys()
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.redirectToJourneys()
    }
  },
  created() {
    const localStorageLoginIsLoading = localStorage.getItem(
      'lxs-v2-is-login-with-google'
    )

    const isWaitingLoginWithGoogle = localStorageLoginIsLoading
      ? localStorageLoginIsLoading === 'true'
      : false

    this.toggleWaitingLogin(isWaitingLoginWithGoogle)
    this.toggleLoading(isWaitingLoginWithGoogle)

    if (window.matchMedia) {
      const mq = window.matchMedia('(max-width: 784px)')

      mq.addListener((mqe) => {
        if (!mqe.matches) {
          this.startBorderAnimation()
        } else {
          if (this.animationIntervalRef) {
            this.stopBorderAnimation()
          }
        }
      })

      if (!mq.matches) {
        this.startBorderAnimation()
      }
    }
  },
  destroyed() {
    this.stopBorderAnimation()
  },
  methods: {
    redirectToJourneys() {
      this.setRouteContext(journeyRouteContext)
      this.$router.push({ name: 'Journeys' })
    },
    startBorderAnimation() {
      this.animationIntervalRef = setInterval(() => {
        this.animationFrame =
          this.animationFrame >= 8 ? 1 : this.animationFrame + 1
      }, 800)
    },
    stopBorderAnimation() {
      clearInterval(this.animationIntervalRef)
    },
    loginWithGoogle() {
      localStorage.setItem('lxs-v2-is-login-with-google', JSON.stringify(true))
      this.toggleWaitingLogin(true)
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().useDeviceLanguage()
      firebase.auth().signInWithRedirect(provider)
    },
    loginWithEmail() {
      this.loginWithEmailIsLoading = true

      fetch(process.env.VUE_APP_AUTH_URL, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ e: this.email, u: window.location.origin })
      })
        .then(() => {
          window.localStorage.setItem('emailForSignIn', this.email)
          this.showLoginWithEmailStepTwo()
          this.loginWithEmailIsLoading = false
        })
        .catch((error) => {
          this.loginWithEmailIsLoading = false
          alert(
            'Parece que ocorreu algum erro com o seu processo de login. Por favor, tente novamente.'
          )
          // eslint-disable-next-line
          console.error(error)
        })
    },
    backToLogin() {
      this.loginWithEmailStepTwo = false
      this.loginWithEmailHelp = false
    },
    showLoginWithEmailHelp() {
      this.loginWithEmailHelp = true
      this.loginWithEmailStepTwo = false
    },
    showLoginWithEmailStepTwo() {
      this.loginWithEmailHelp = false
      this.loginWithEmailStepTwo = true
    },
    ...mapActions('system', ['setRouteContext']),
    ...mapActions('account', ['toggleWaitingLogin']),
    ...mapActions('journeys', ['toggleLoading'])
  }
}
</script>

<style lang="scss" scoped>
.fade-to-login-enter-active,
.fade-to-login-leave-active {
  transition: opacity 0.2s;
}

.fade-to-login-enter-to,
.fade-to-login-leave {
  opacity: 1;
}

.fade-to-login-enter,
.fade-to-login-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loading-background {
  background: $color-white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  border: 0px solid transparent;

  * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.login-background {
  background: $color-blue-primary;
  width: 100%;
  height: 100%;
  position: fixed;
  border: 0px solid transparent;

  @media #{$media_query_s} {
    background: $color-white !important;
  }

  .frame {
    width: 100%;
    height: 100%;
    position: fixed;

    @media #{$media_query_s} {
      display: none;
    }

    &.frame-1 {
      background: linear-gradient(45deg, #67f714, #0bcae3);
    }

    &.frame-2 {
      background: linear-gradient(90deg, #67f714, #0bcae3);
    }

    &.frame-3 {
      background: linear-gradient(135deg, #67f714, #0bcae3);
    }

    &.frame-4 {
      background: linear-gradient(180deg, #67f714, #0bcae3);
    }

    &.frame-5 {
      background: linear-gradient(225deg, #67f714, #0bcae3);
    }

    &.frame-6 {
      background: linear-gradient(270deg, #67f714, #0bcae3);
    }

    &.frame-7 {
      background: linear-gradient(315deg, #67f714, #0bcae3);
    }

    &.frame-8 {
      background: linear-gradient(360deg, #67f714, #0bcae3);
    }
  }
}

.login-box {
  background-color: $color-white;
  border: 16px solid transparent;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-clip: padding-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @media #{$media_query_s} {
    border: none !important;
  }

  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    hr {
      border: solid 1px $color-gray-20;
    }

    > .logo {
      text-align: center;
      margin-bottom: 55px;
    }

    > .login-google {
      text-align: center;
      font-family: 'Rubik';
      font-size: 12px;
    }
  }

  > .email-step-two {
    > .logo {
      margin-bottom: 21px;
    }
  }

  small {
    font-size: 12px;
    line-height: 20px;

    color: $color-gray-60;
    text-transform: none;
    font-family: Georgia, sans-serif;
  }

  .bottom-help-text {
    position: fixed;
    bottom: 16px;
    display: block;
    max-width: 310px;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .terms-of-use {
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    line-height: 20px;
    color: $color-gray-80;
    text-transform: uppercase;

    @include breakpoint-small {
      bottom: 8px;
    }
  }
}

.login-email-next {
  margin-top: 16px;
  margin-bottom: 24px;
}

hr {
  width: 100%;
}

.login-google {
  margin-top: 24px;
  p {
    text-transform: uppercase;
    margin-bottom: 8px;
  }
}

.email-step-one,
.email-step-two {
  width: 100%;
  max-width: 310px;
}

.email-step-two,
.email-problems {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  span {
    color: $color-blue-primary;
    margin: 0 auto;
    display: block;
  }

  p {
    color: $color-gray-80;
    font-weight: bold;

    &.-normal {
      font-weight: normal;
    }
  }

  .link {
    font-size: 12px;
    text-decoration-line: underline;
    color: $color-gray-80;
    display: block;
    cursor: pointer;

    &:first-of-type {
      margin-top: 16px;
    }
  }
}

.email-problems {
  box-shadow: 0px 6px 12px rgba(51, 51, 51, 0.25);
  width: 100%;
  max-width: 360px;
  padding: 32px 25px 0px;
  box-sizing: border-box;

  @media #{$media_query_s} {
    box-shadow: none;
  }

  p.help-text {
    font-weight: normal;
    text-transform: none;
    font-size: 14px;
    line-height: 18px;
    text-align: left;

    ul {
      text-align: left;
      padding-inline-start: 20px;

      li {
        margin: 5px 0;
      }
    }

    @include breakpoint-small {
      font-size: 12px;
      line-height: 14px;
    }
  }

  span.link {
    text-decoration-line: none;
    cursor: default;
  }

  .link {
    display: block;
    margin: 16px 0;

    @include breakpoint-small {
      margin: 56px 0;
    }
  }
}

#terms-and-privacy {
  position: fixed;
  bottom: 35px;
}

a:link,
a:visited {
  color: #666666;
}
</style>
