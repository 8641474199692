<template>
  <sidebar class="article-sidebar">
    <div class="sidebar-content-container">
      <div class="title-container">
        <h4 class="title">{{ currentJourneyArticle.name }}</h4>
        <span v-if="windowWidth < 800" class="bm-cross" style="position: fixed; width: 2px; height: 18px; border-radius: 4px; transform: rotate(-45deg);" @click="$router.go(-1)"></span>
        <span v-if="windowWidth < 800" class="bm-cross" style="position: fixed; width: 2px; height: 18px; border-radius: 4px; transform: rotate(45deg);" @click="$router.go(-1)"></span>
        <vertical-progress-view
          v-if="currentChapterInteractions.length > 0 && windowWidth < 800"
          :items="items"
          @onChangeCurrentItem="changeChapter"
        />
      </div>
      <vertical-progress-view
        v-if="currentChapterInteractions.length > 0 && windowWidth >= 800"
        :items="items"
        @onChangeCurrentItem="changeChapter"
      />

      <footer v-if="windowWidth >= 800" class="footer">
        <icon-base name="chevron-left" color="#999" />
        <button type="button" @click="$router.go(-1)">Voltar para o curso</button>
      </footer>
    </div>
  </sidebar>
</template>

<script>
import Sidebar from '@/core/components/Sidebar.vue'
import VerticalProgressView from '@/core/components/VerticalProgressView.vue'

import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'ArticleChapterSidebar',
  components: { Sidebar, VerticalProgressView },
  props: {
    articleChapters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    windowWidth() {
      return window.innerWidth
    },
    items() {
      if (this.articleChapters && this.currentChapterInteractions.length > 0) {
        return this.articleChapters.map((chapter, index) => {
          const currentInteraction = this.currentChapterInteractions.find(
            (interaction) => interaction.chapterIndex === index
          )

          return { ...currentInteraction, name: chapter.title }
        })
      }

      return []
    },
    ...mapState('articles', ['currentChapterInteractions']),
    ...mapGetters('articles', ['currentJourneyArticle'])
  },
  methods: {
    changeChapter(index) {
      this.setArticleCurrentChapterByIndex(index)
    },
    ...mapActions('articles', ['setArticleCurrentChapterByIndex'])
  }
}
</script>

<style lang="scss" scoped>
.article-sidebar {
  height: 100%;
  position: fixed;
  z-index: 10;

  .sidebar-content-container {
    position: relative;
    height: inherit;

    .title-container {
      padding: 24px;
      background-color: #000000;

      > .title {
        font-family: Rubik;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0;
      }

      @media (max-width: 800px) {
        width: 100vw;
        padding: 16px;

        > .title {
          font-size: 16px;
          padding-bottom: 8px;
        }

      }
    }

    .footer {
      align-items: center;
      border-top: 2px solid #666;
      bottom: 85px;
      display: flex;
      justify-content: center;
      left: 10%;
      padding-top: 12px;
      position: absolute;

      button {
        background: none;
        border: 0;
        color: #999;
        cursor: pointer;
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        margin-left: 8px;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}

@media(max-width: 800px) {
  .article-sidebar {
    visibility: hidden;
  }

  .title-container {
    visibility: visible;

    > h4 {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 75vw;
      height: 1.2em;
      white-space: nowrap;
    }

    span {
      background-color: #bbb;
      top: 16px;
      right: 16px;
    }
  }
}

</style>
