import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import authConfig from '@/core/config/firestore-auth';
export const db = firebase.initializeApp(authConfig).firestore();
const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            try {
                unsubscribe();
                resolve(user);
            }
            catch (err) {
                reject(err);
            }
        }, reject);
    });
};
const { Timestamp, FieldValue, FieldPath } = firebase.firestore;
export { Timestamp, FieldValue, FieldPath, firebase, getCurrentUser };
