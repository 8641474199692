<template>
  <div class="library">
    <div class="header">
      <base-header type="header" class="journey-header">
        <template v-slot:default>
          <heading-with-before color="white" :before-color-variation="true">
            {{ `Biblioteca: módulo ${moduleIndexPadded}` }}
          </heading-with-before>
        </template>
        <template v-slot:footer>
          <heading v-if="currentModuleName" tag="h4" color="white">
            {{ currentModuleName }}
          </heading>
          <heading
            tag="h4"
            text-transform="none"
            font-weight="normal"
            color="white"
          >
            Uma seleção de conteúdos para você ampliar seus conhecimentos nesse
            módulo.
          </heading>
        </template>
      </base-header>
    </div>
    <main-container>
      <heading tag="h4">Tera Originals</heading>
      <div v-if="!originalsIsLoading" class="content-container">
        <div class="card-container">
          <originals-material-card
            v-for="(originals, i) in currentOriginals"
            :key="'originals_' + i"
            :originals="originals"
            :route-params="childRouteParams"
          ></originals-material-card>
        </div>
      </div>
    </main-container>
  </div>
</template>

<script>
import MainContainer from '@/core/components/MainContainer.vue'
import OriginalsMaterialCard from '@/modules/Journeys/components/OriginalsMaterialCard.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ModuleLibrary',
  components: {
    MainContainer,
    OriginalsMaterialCard
  },
  props: {
    teraId: {
      type: String,
      required: true
    },
    moduleIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    childRouteParams() {
      return { teraId: this.teraId }
    },
    currentModuleName() {
      return this.currentUserJourney &&
        this.currentUserJourney.modules &&
        this.currentUserJourney.module > 0
        ? this.currentUserJourney.modules[this.moduleIndex].name
        : null
    },
    currentOriginals() {
      if (!this.currentJourneyBlocks) {
        return []
      }

      const moduleOriginals = this.currentJourneyBlocks
        .filter(
          (jb) =>
            jb.type === 'originals' && jb.moduleIndex === this.moduleIndex - 1
        )
        .sort((a, b) => (a.index > b.index ? 1 : -1))

      var current = moduleOriginals.map((mo) => {
        const originalsId = mo.originalsRef.path.split('/')[1]
        return this.currentUserOriginals.find((o) => o.id === originalsId)
      })

      return current
    },
    moduleIndexPadded() {
      return String(this.moduleIndex).padStart(2, '0')
    },
    ...mapGetters('journeys', [
      'currentUserJourney',
      'currentJourneyBlocks',
      'journeyBlocksHasUpdates',
      'currentUserOriginals',
      'isLoading',
      'originalsIsLoading'
    ]),
    ...mapGetters('account', ['person'])
  },
  mounted() {
    const {
      journeys,
      modules,
      moduleLibrary
    } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, moduleLibrary]

    this.setRouteBreadcrumb(breadcrumb)

    this.setCurrentTeraId(this.teraId)
    this.setCurrentModuleIndex(this.moduleIndex)
  },
  methods: {
    ...mapActions('journeys', [
      'setCurrentTeraId',
      'setCurrentModuleIndex',
      'updateJourneyBlocksWithStream'
    ]),
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.library {
  width: 100%;
  box-sizing: border-box;
  text-align: left;

  .main-container {
    max-width: $container-m;
    margin: $space-xl auto 0;

    @include breakpoint-small {
      margin: $space-l auto 0;
      width: 100%;
    }

    > .content-container {
      margin-top: $space-s;
      margin-bottom: 64px;

      > .card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > * {
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
