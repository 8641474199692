export default {

  trackLogin: {
    eventId: 'pe7435824_login_lxs',
    portalId: '7435824'
  },
  trackCertificateEventDPW: {
    eventId: 'pe7435824_certificado_evento_dpw',
    portalId: '7435824'
  },
  trackCertificateEventMKT: {
    eventId: 'pe7435824_certificado_evento_mkt',
    portalId: '7435824'
  },
  trackCertificateCompletion: {
    eventId: 'pe7435824_certificado_conclusao_de_curso',
    portalId: '7435824'
  },
  trackViewPage: {
    eventId: 'pe7435824_lxs_views_com_e_mail',
    portalId: '7435824'
  },
  trackButtonRefer: {
    eventId: 'pe7435824_indique_cliques',
    portalId: '7435824'
  },
  trackBannerMkt: {
    eventId: 'pe7435824_banner_mkt_cliques',
    portalId: '7435824'
  }
}
