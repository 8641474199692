<template>
  <div id="app">
    <navbar v-if="!isLoginRoute && !isResponsiveArticleRoute"></navbar>
    <breadcrumb></breadcrumb>
    <debounce-load :is-loading="isLoading">
      <router-view />
    </debounce-load>
    <journey-update-notification-tile></journey-update-notification-tile>
  </div>
</template>

<script>
import DebounceLoad from '@/core/components/DebounceLoad.vue'
import Navbar from '@/core/components/Navbar.vue'
import Breadcrumb from '@/core/components/Breadcrumb.vue'
import JourneyUpdateNotificationTile from '@/modules/Journeys/components/JourneyUpdateNotificationTile.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    DebounceLoad,
    Navbar,
    Breadcrumb,
    JourneyUpdateNotificationTile
  },
  data() {
    return {
      isResponsiveArticleRoute: false
    }
  },
  computed: {
    isLoginRoute() {
      return this.$route.name === 'Login'
    },
    isVisible() {
      return this.isLoading ? '-invisible main-view' : 'main-view'
    },
    ...mapGetters('journeys', ['isLoading'])
  },
  watch: {
    $route (to, from) {
      if (to.path.indexOf(/article/) > -1) {
        this.isResponsiveArticleRoute = true
      } else {
        this.isResponsiveArticleRoute = false
      }

      if (to.path.includes('MKTEV_REM_2021_1')) {
        this.loadChat()
      } else if (from.path.includes('MKTEV_REM_2021_1')) {
        this.removeChat()
      }
    }
  },
  beforeMount() {
    this.removeChat()
  },
  methods: {
    loadChat() {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load({ widgetOpen: false })
      }
    },
    removeChat() {
      window.hsConversationsSettings = {
        loadImmediately: false
      }
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.remove()
      }
    }
  }
}
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

html,
body,
#app {
  min-height: 100%;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  margin: 0;
}

.main-view {
  opacity: 1;
  transition: opacity 0.1s ease-in-out;

  &.-invisible {
    opacity: 0;
  }
}

.journey-update-notification-tile {
  position: fixed;
  bottom: $space-m;
  margin-left: 50%;
  transform: translateX(-50%);
}

#app {
  font-family: $font-georgia;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-black;
  display: flex;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-onboarding-enter-active,
.fade-onboarding-leave-active {
  transition: opacity 0.3s;
}

.fade-onboarding-enter-to,
.fade-onboarding-leave {
  opacity: 1;
}

.fade-onboarding-enter,
.fade-onboarding-leave-to {
  opacity: 0;
}
article {
  position: relative;
  width: 100% !important;
}
</style>
