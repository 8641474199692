<template>
  <base-header type="header" class="journey-header">
    <template v-slot:default>
      <img
        class="image"
        :src="courseIcon.url"
        :alt="courseIcon.alt"
        :class="{ 'no-image': imageDidNotLoad }"
        @error="imageLoadError"
      />
      <heading color="white">{{ courseName }}</heading>
    </template>
    <template v-slot:footer>
      <heading color="white" tag="h4">{{ journeyName }}</heading>
    </template>
  </base-header>
</template>

<script>
export default {
  name: 'JourneyHeader',
  status: 'prototype',
  release: '0.0.1',
  props: {
    courseName: String,
    journeyName: String,
    courseIcon: Object
  },
  data() {
    return {
      imageDidNotLoad: false
    }
  },
  methods: {
    imageLoadError() {
      this.imageDidNotLoad = true
    }
  }
}
</script>

<style lang="scss" scoped>
.journey-header {
  > .container {
    > .image {
      height: 48px;
      margin-bottom: 16px;

      &.no-image {
        display: none !important;
      }

      @media (min-width: $container-xl) {
        position: absolute;
        height: 120px;
        top: 0;
        left: -$space-m;
        transform: translateX(-100%);
      }
    }
  }
  ::v-deep.container {
    height: 119px;

    @include breakpoint-small {
      height: 174px;
    }
  }
}
</style>
