<template>
  <card-router-link class="material-card-link" :route="route" @click="click">
    <small-square-base-card
      class="material-card"
      background-color="gray-100"
      text-color="none"
    >
      <template v-slot:content>
        <div class="content-container">
          <heading class="title" tag="h4" color="white" font-weight="normal">
            {{ title }}
          </heading>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-container">
          <heading
            v-if="subtitle"
            class="subtitle"
            color="gray-60"
            tag="h4"
            font-weight="normal"
            text-transform="none"
          >
            {{ subtitle }}
          </heading>
        </div>
      </template>
    </small-square-base-card>
  </card-router-link>
</template>

<script>
import CardRouterLink from '@/core/components/CardRouterLink'

export default {
  name: 'MaterialCard',
  status: 'prototype',
  release: '0.0.1',
  components: {
    CardRouterLink
  },
  props: {
    /**
     * The object that represents a router link valid route, or an external link
     */
    route: {
      type: Object,
      default: null,
      validator: (value) => {
        if (value && typeof value === 'object') {
          return !!(value.name || value.href)
        }
        return value == null
      }
    },
    /**
     * A Title for this component, required
     */
    title: {
      type: String,
      default: '',
      required: true,
      validator: (value) => {
        return value !== ''
      }
    },
    /**
     * A SubTitle for this component
     */
    subtitle: {
      type: String,
      default: ''
    },
    /**
     * Use this prop to add image url
     */
    imageUrl: {
      type: String,
      default: ''
    },
    /**
     * Use this prop to add image description for alt
     */
    imageDescription: {
      type: String,
      default: 'Imagem do card'
    }
  },
  computed: {
    goToRoute() {
      return {
        name: 'ModuleClassContent'
        // params: {
        //   teraId: this.journey.teraId
        // }
      }
    }
  },
  methods: {
    click() {
      var eventCategory = 'Article'
      if (this.content.type === 'video') {
        eventCategory = 'Video'
      }

      this.$ga.event(eventCategory, 'click', this.content.title)

      this.$amplitude.getInstance().logEvent('class before content clicked', {
        name: this.currentClass.name,
        order: this.currentClass.index,
        'tera id': this.currentCourse.teraId,
        expert: this.currentClass.expert ? this.currentClass.expert.name : '',
        place: this.currentClass.place ? this.currentClass.place.name : '',
        date: new Date(this.currentClass.date),
        'start time': this.currentClass.startTime,
        'end time': this.currentClass.endTime,
        user: this.user.uid,
        'content title': this.content.title,
        'content type': this.content.type,
        'content minutes': this.content.expectedMinutes,
        'content author': this.content.author,
        moment: this.currentJourneyMoment,
        value: 'high'
      })
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .small-square-base-card {
  height: auto !important;
  min-height: 0 !important;

  .top {
    min-height: 0 !important;
  }
}

.material-card-link {
  max-width: 368px;
  width: 100%;

  @media (width: 768px) {
    width: calc(50% - 8px);
  }
}

.material-card {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  > .slot-container {
    > .top {
      > .image-container {
        min-height: 207px;

        > img {
          width: 100%;
        }

        @include breakpoint-small {
          height: fit-content;
        }
      }
      @include breakpoint-small {
        max-height: 100px;
      }
    }

    > .content {
      > .content-container {
        height: 84px;
        margin: $space-s $space-s 0 $space-s;

        @include breakpoint-small {
          margin-top: $space-s;
        }
      }
    }

    > .footer {
      > .footer-container {
        padding: 0px $space-s $space-s $space-s;
      }
    }
  }
  @include breakpoint-small {
    min-height: unset;
  }
}
</style>
