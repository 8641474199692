<template>
  <main-container class="courses">
    <heading
      v-if="personFirstName"
      tag="h2"
      font-weight="light"
      color="black"
    >
      Olá, {{ personFirstName }}!
    </heading>
    <heading v-else tag="h2" font-weight="light" color="black">Olá!</heading>
    <div v-if="userHasCourses" class="current-courses">
      <heading tag="h4" color="black">Seus cursos</heading>
      <div class="cards">
        <journey-card
          v-for="(j, i) in userJourneys"
          :key="i + '-userJourneys'"
          background-color="black"
          :journey="j"
          class="card"
        ></journey-card>
      </div>
    </div>
    <div v-else class="no-courses">
      <heading tag="h2" color="black">Você ainda não é estudante na Tera</heading>
      <p>
        Não encontramos nenhum curso para o e-mail
        <span id="email-formatter">"{{ personEmail }}"</span>. Verifique se este
        é o mesmo e-mail que você forneceu durante a sua inscrição.
      </p>
      <p>
        Caso você tenha entrado com um e-mail diferente, saia e entre novamente
        <a
          href="https://app.somostera.com/login"
          @click="logout"
        >clicando aqui</a>
        .
      </p>
      <p>
        Se você entrou usando o mesmo e-mail da sua inscrição, fale com nosso
        suporte a estudante no
        <a
          href="mailto:meajuda@somostera.com"
          target="_blank"
        >meajuda@somostera.com</a>
        .
      </p>
      <p>
        Ainda não se inscreveu mas tem interesse em conhecer nossos programas?
        Veja abaixo a lista de cursos da Tera.
      </p>
    </div>

    <div class="other-courses">
      <heading v-if="userHasCourses" tag="h4" color="black">Outros cursos da Tera</heading>
      <heading v-else tag="h4" color="black">Inscreva-se</heading>
      <mkt-ad v-if="showBanner"></mkt-ad>
      <div class="cards">
        <journey-card
          v-for="(j, i) in otherJourneysWithoutUserJourneys"
          :key="i + '-otherJourneys'"
          :journey="j.journey"
          :current-course="false"
          :route="j.route"
          class="card"
        ></journey-card>
      </div>
    </div>
  </main-container>
</template>

<script>
import MktAd from '@/modules/Journeys/components/MktAd'
import JourneyCard from '@/modules/Journeys/components/JourneyCard.vue'
import MainContainer from '@/core/components/MainContainer'
import Journey from '@somostera/tera-models/models/Journey'
import { firebase } from '@/core/services/firebase'
import { getAllCourses } from '@/modules/Journeys/services/courses'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Journeys',
  components: {
    JourneyCard,
    MainContainer,
    MktAd
  },
  data () {
    return {
      otherCourses: []
    }
  },
  computed: {
    personIsEnrolledInDPW () {
      if (!this.userJourneys) {
        return false
      }

      const hasDPW = this.userJourneys.filter((uj) => {
        if (!uj.courseInfo) return
        return uj.teraId.includes('EV_REM')
      })
      return !!hasDPW
    },
    personFirstName () {
      if (!this.person) {
        return ''
      }

      return this.person.name.split(' ')[0]
    },
    personEmail () {
      return (
        firebase.auth().currentUser.email ??
        localStorage.getItem('emailForSignIn')
      )
    },
    otherJourneysWithoutUserJourneys () {
      if (!this.userJourneys) {
        return this.otherCourses
      }
      const userJourneysAcronyms = this.userJourneys.map((uj) => {
        if (!uj.courseInfo) return
        const coursesWithoutEvents = uj.teraId.includes('TR') || uj.teraId.includes('EV_REM') || uj.teraId.includes('DPW') ? '' : uj.courseInfo.acronym

        return coursesWithoutEvents
      }).filter((a) => a !== '')

      return this.otherCourses.filter((otherJourney) => {
        if (otherJourney.journey.courseInfo.acronym === 'dpw') return
        return userJourneysAcronyms.indexOf(otherJourney.journey.courseInfo.acronym) === -1
      })
    },
    userHasCourses () {
      return this.userJourneys && this.userJourneys.length > 0
    },
    showBanner () {
      const freeCourses = this.getFreeCourses()
      return freeCourses.length === this.userJourneys.length
    },
    ...mapGetters('journeys', ['userJourneys']),
    ...mapGetters('account', ['person'])
  },
  mounted () {
    this.getCourses()
    this.setRouteBreadcrumb(null)

    this.$amplitude.getInstance().logEvent('journeys seen', {
      user: this.user ? this.user.uid : '',
      value: 'low'
    })

    localStorage.removeItem('emailForSignIn')
  },
  methods: {
    ...mapActions('journeys', [
      'unbindStreamedJourneyBlocks',
      'clearCurrentJourneyStates'
    ]),
    ...mapActions('system', ['setRouteBreadcrumb']),
    logout () {
      firebase.auth().signOut()
      this.$store.dispatch('account/resetState')
    },
    beforeRouteEnter (to, from, next) {
      next((vm) => {
        vm.unbindStreamedJourneyBlocks()
      })
    },
    async getCourses () {
      try {
        const validFormats = ['Part-time', 'Full-time']
        const courses = await getAllCourses()
        const coursesData = []
        courses.forEach((course) => {
          if (course.public === true) {
            const courseFormat = []
            course.formats.forEach((format) => {
              if (validFormats.includes(format.name)) { courseFormat.push(format.name) }
            })
            const courseFormatName = courseFormat.join([' e '])
            coursesData.push(
              {
                journey: new Journey(courseFormatName, {
                  acronym: course.acronym,
                  name: course.name
                }),
                route: {
                  href: course.siteInfo.siteUrl
                }
              }
            )
          }
        })
        this.otherCourses = coursesData
      } catch (error) {
        console.error(error)
      }
    },
    getFreeCourses () {
      return this.userJourneys.filter(journey => {
        return journey.teraId.includes('EV')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#email-formatter {
  font-weight: bold;
  color: #00cbe0;
}

.courses {
  max-width: $container-xl;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;

  > h2 {
    margin: 56px 0 24px 0;
  }

  > .no-courses {
    width: 100%;
    max-width: 464px;
    margin-bottom: 64px;

    p {
      font-family: 'Rubik', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: $color-black;
    }

    a {
      text-decoration: none;
      color: $color-blue-primary;
    }
  }

  > .current-courses,
  .other-courses {
    width: 100%;
    text-align: left;

    > .cards {
      height: auto;
      margin: 8px 0 64px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &::after {
        content: '';
        width: calc(100% / 3 - #{$space-xxs});
        min-height: 1px;

        @include breakpoint-medium {
          width: calc(50% - #{$space-xxs});
        }

        @include breakpoint-small {
          width: 100%;
        }
      }

      @include breakpoint-extra-large {
        justify-content: space-between;
      }

      @include breakpoint-small {
        justify-content: center;
      }

      > .card {
        width: calc(100% / 3 - #{$space-xxs});
        margin: #{$space-xxs / 2} 0 #{$space-xxs};

        @include breakpoint-medium {
          width: calc(50% - #{$space-xxs});
        }

        @include breakpoint-small {
          width: 100%;
        }
      }
    }
  }
}
</style>
