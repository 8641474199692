import Vue from 'vue';
export default Vue.extend({
    name: 'ChevronLeft',
    props: {
        size: {
            type: [String, Number],
            default: 16
        },
        color: {
            type: String,
            default: '#999'
        }
    }
});
