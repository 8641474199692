<template>
  <div class="class-live-embed">
    <div class="frame-container">
      <div
        v-if="!alreadyAired && !mustForceStartVideoPlayer"
        class="before-class"
      >
        <img
          v-if="journeyIcon"
          class="journey-icon"
          :src="journeyIcon"
          alt="Ícone do curso"
        />
        <heading class="title" tag="h2" color="white">
          a transmissão ainda não começou
        </heading>
        <heading tag="h5" color="white">
          Não se preocupe, esta página atualizará automaticamente quando começar
          :)
        </heading>
        <small>
          Caso não atualize,
          <span @click="forceStartVideoPlayer">
            clique aqui para iniciar a transmissão
          </span>
        </small>
      </div>
      <iframe
        v-else
        class="embed"
        :src="src"
        width="100%"
        height="100%"
        scrolling="no"
        allowfullscreen
      ></iframe>
    </div>
    <div
      v-if="
        (questionSrc && alreadyAired && !transmitionEnded) ||
          mustForceStartVideoPlayer
      "
      class="question"
    >
      <div class="text">
        <heading tag="h4" text-transform="none" font-weight="light">
          Tem uma pergunta?
          <a :href="questionSrc" target="_blank">
            Envie sua dúvida para gente aqui
          </a>
          .
        </heading>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassLiveEmbed',
  props: {
    src: {
      type: String,
      required: true
    },
    questionSrc: String,
    journeyIcon: String,
    startTime: {
      type: Date,
      required: true
    },
    endTime: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      dateNow: new Date(),
      interval: null,
      mustForceStartVideoPlayer: false
    }
  },
  computed: {
    alreadyAired() {
      if (!this.startTime) return false

      var tzDifference = this.startTime.getTimezoneOffset()
      var offsetTime = new Date(
        this.startTime.getTime() + tzDifference * 60 * 1000
      )

      return offsetTime < this.dateNow
    },
    transmitionEnded() {
      if (!this.endTime) return false

      var tzDifference = this.endTime.getTimezoneOffset()
      var offsetTime = new Date(
        this.endTime.getTime() + tzDifference * 60 * 1000
      )

      return offsetTime < this.dateNow
    }
  },
  mounted() {
    if (!this.alreadyAired) {
      this.checkForAiredTime()
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    forceStartVideoPlayer() {
      this.mustForceStartVideoPlayer = true
    },
    checkForAiredTime() {
      this.interval = setInterval(() => {
        this.dateNow = new Date()

        if (this.alreadyAired) {
          clearInterval(this.interval)
        }
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.class-live-embed {
  width: 100%;
  height: auto;
  position: relative;
  max-width: 752px;
  background-color: $color-black;
  margin-bottom: $space-xl;

  > .frame-container {
    width: 100%;
    height: 423px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint-small {
      height: 0;
      padding-bottom: 58%;
      position: relative;
    }

    > .before-class {
      width: 100%;
      max-width: 368px;
      text-align: center;

      @include breakpoint-small {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px;
        box-sizing: border-box;
      }

      > .journey-icon {
        margin-bottom: $space-s;

        @include breakpoint-small {
          display: none;
        }
      }

      > .title {
        margin-bottom: $space-s;
      }

      > small {
        color: $color-white;
        margin-top: $space-s;
        display: block;
        font-family: 'Rubik';

        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    > .embed {
      width: 100%;
      border: none;

      @include breakpoint-small {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
    }
  }

  > .question {
    width: 100%;
    height: 80px;
    color: $color-black;
    background-color: $color-gray-20;
    display: flex;

    > .text {
      display: flex;
      align-items: center;
      margin-left: 16px;
      > h4 {
        > a {
          color: $color-blue-primary;
        }
      }
    }
  }
}
</style>
