<template>
  <div v-if="editor" class="editor">
    <editor-content class="preview-container" :editor="editor" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { defaultExtensions } from '@tiptap/starter-kit'

import Underline from '@tiptap/extension-underline'
import Typography from '@tiptap/extension-typography'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'

import Video from '@/modules/Journeys/components/Article/components/Video'
import PageBreak from '@/modules/Journeys/components/Article/components/PageBreak'

export default Vue.extend({
  name: 'ContentPreview',
  components: {
    EditorContent
  },
  props: {
    content: Object
  },
  data() {
    return {
      editor: new Editor({
        extensions: [...defaultExtensions(), Underline, TextAlign, Link, Typography, PageBreak, Video, Image]
      })
    }
  },
  watch: {
    content() {
      this.editor.commands.setContent(this.content)
    }
  },
  async mounted() {
    if (this.content) {
      this.editor.commands.setContent(this.content)
      this.editor.setEditable(false)
    }
  }
})
</script>

<style lang="scss">
:root {
  --plyr-color-main: #04a0c3;
  --plyr-font-family: 'Rubik';
}

.editor {
  height: 100%;
  font-family: 'Rubik';

  .preview-container {
    margin: 0 auto;
    padding: 32px 48px 0;
    width: 560px;

    .ProseMirror {
      > * {
        display: inline-block;
        margin: 0;
        padding: 0;

        &:first-child {
          margin-top: 0;
          padding-top: 0;
        }
      }

      > img {
        width: 100%;
        height: auto;

        & + p {
          margin-top: 24px;
        }
      }

      ul {
        list-style: disc !important;
        margin: 0;
        padding: 0;

        li {
          display: flex;

          &::before {
            content: '•';
            font-size: 32px;
            font-family: 'Rubik', sans-serif;
            font-weight: bold;
            letter-spacing: -2%;
            line-height: 32px;
            margin: 16px 8px 0 0;
            min-width: 48px;
            text-align: center;
          }
        }
      }

      ol {
        counter-reset: list-counter;
        list-style: none;
        margin: 0;
        padding: 0;

        & + p {
          margin-top: 24px;
        }

        li {
          &:first-child {
            margin-top: 0;
          }

          margin-top: 16px;
          counter-increment: list-counter;
          display: flex;

          > p {
            margin: 0;
          }

          &::before {
            content: '0' counter(list-counter);
            font-size: 32px;
            font-family: 'Rubik', sans-serif;
            font-weight: bold;
            letter-spacing: -2%;
            line-height: 32px;
            margin-right: 8px;
            min-width: 48px;
          }
        }
      }

      h1 {
        border-left: 8px solid #99fb8c;
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -2%;
        line-height: 48px;
        margin-left: -24px;
        margin-bottom: 20px;
        padding-left: 16px;
        text-transform: uppercase;
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;

        & + ol {
          margin-top: 8px;
        }
      }

      strong {
        font-weight: 700;
      }

      em {
        font-style: italic;
      }

      img {
        height: auto;
        max-width: 100%;
      }

      blockquote {
        margin: 0;

        & + p {
          line-height: 24px;
          margin-bottom: 40px;
          text-transform: uppercase;
        }

        > p {
          > em {
            font-family: Georgia, serif;
            font-style: italic;
            font-weight: normal;
            font-size: 32px;
            line-height: 40px;
          }
        }
      }

      a {
        color: #04a0c3 !important;
        font-weight: bold;
        text-decoration: underline !important;
      }

      p {
        width: 100%;
        margin: 16px 0;
        font-size: 16px;
        line-height: 24px;

        & + h2 {
          margin-top: 8px;
        }
      }

      .video-wrapper {
        height: auto;
        overflow: hidden;
        width: 100%;
        margin: 20px 0;

        .plyr {
          &__video-wrapper {
            width: 100%;
          }
        }

        video {
          height: 100%;
          width: 100%;
        }

        & + h2,
        & + p {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
