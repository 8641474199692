import amplitude from 'amplitude-js'

export default {
  install: (Vue, { apiKey, userId, config }) => {
    amplitude.getInstance().init(apiKey, userId, config)

    Vue.prototype.$amplitude = amplitude

    if (window) {
      window.amplitude = amplitude
    }
  }
}
