import Vue from 'vue'
import Vuex from 'vuex'
import { system } from '@/core/store/system'
import { account } from '@/core/store/account'
import { events } from '@/core/store/events'
import { journeys, journeysWatchers } from '@/modules/Journeys/store/journeys'
import {
  articles,
  articlesWatchers
} from '@/modules/Journeys/store/articles.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    journeys,
    system,
    account,
    articles,
    events
  }
})

const watchers = { ...journeysWatchers, ...articlesWatchers }

for (var watcherName in watchers) {
  const watcherWithStore = watchers[watcherName](store)
  store.watch(watcherWithStore.state, watcherWithStore.watcher)
}

export default store
