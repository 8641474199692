import axios from '@/core/api/axios.config'

export default {
  uploadContent: file => {
    const bucket = 'tera-materials'
    const filename = file.name
    const contentType = file.type

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_STORAGE_UPLOAD_URL, {
          bucket,
          filename,
          contentType
        })
        .then(res => {
          fetch(res.data, {
            method: 'put',
            body: file
          })
            .then(() => {
              resolve(`https://storage.googleapis.com/${bucket}/${filename}`)
            })
            .catch(err => {
              reject(err)
            })
        })
    })
  }
}
