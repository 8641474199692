<template>
  <div :class="[cardCertificationStyle, '-background-' + backgroundColor]">
    <img class="image" :src="imageUrl" :alt="imageDescription" />
    <div class="content">
      <div class="text">
        <h2>{{ title }}</h2>
        <heading tag="h4" font-weight="light" text-transform="none">
          {{ subtitle }}
        </heading>
      </div>
      <div class="button">
        <base-button
          type="a"
          :href="certificationUrl"
          :state="buttonState"
          :color="buttonBackgroundColor"
          :font-color="buttonFontColor"
          @click="$emit('click')"
        >
          {{ buttonText }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CertificationCard',
  props: {
    active: Boolean,
    title: String,
    subtitle: String,
    imageUrl: String,
    imageDescription: String,
    buttonText: String,
    buttonState: String,
    buttonBackgroundColor: String,
    buttonFontColor: String,
    certificationUrl: String,
    backgroundColor: {
      type: String,
      default: 'orange-to-red',
      validator: (value) => {
        return value !== '' || value !== undefined
      }
    }
  },
  computed: {
    cardCertificationStyle() {
      if (this.active === true) {
        return 'cardCertification -active'
      } else {
        return 'cardCertification -inactive'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cardCertification {
  max-width: 752px;
  width: 100%;
  height: 302px;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $space-xl;

  @include breakpoint-small {
    margin-left: 0;
    height: 544px;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    justify-content: unset;
    flex-direction: column;
  }

  > .image {
    width: 336px;
    height: 238px;
    box-shadow: $shadow_xs;

    @include breakpoint-small {
      width: 100%;
      height: auto;
      height: unset;
    }
  }

  > .content {
    max-width: 336px;
    width: 100%;
    height: 238px;
    margin-left: $space-s;
    display: flex;
    flex-direction: column;
    position: relative;

    @include breakpoint-small {
      position: unset;
      max-width: none;
      margin-top: $space-m;
      margin-left: 0;
    }

    > .text {
      > h2 {
        margin-top: 0;
        margin-bottom: $space-xxs;
        font-family: 'Rubik';
        font-size: 32px;
        line-height: 32px;
        font-weight: lighter;
        text-transform: uppercase;
        // margin-bottom: 16px;

        @include breakpoint-small {
          margin-top: 0;
        }
      }

      > h4 {
        text-transform: initial;
        // margin-bottom: 26px;

        @include breakpoint-small {
          margin-bottom: $space-xxs;
        }
      }
    }

    > .button {
      width: 100%;
      position: absolute;
      bottom: 0;

      @include breakpoint-small {
        width: calc(100% - #{$space-s * 2});
        position: absolute;
        bottom: $space-s;
        height: 48px;
      }
    }
  }

  &.-active {
    background: $color-orange-to-red;
  }

  &.-inactive {
    background-color: $color-gray-60;
  }
}
</style>
