import { db } from '@/core/services/firebase'

const dbPeople = db.collection('people')

export default {
  getPerson: async uid => {
    try {
      const personDocumentSnapshot = await dbPeople.doc(uid).get()
      if (!personDocumentSnapshot.exists) return
      const person = {
        ...personDocumentSnapshot.data(),
        id: personDocumentSnapshot.id
      }
      return person
    } catch (err) {
      // eslint-disable-next-line
      console.error(err)
    }
  }
}
