import journeyConverter from '@somostera/tera-models/models/Journey/converters'
import peopleConverter from '@somostera/tera-models/models/People/converters'
import placeConverter from '@somostera/tera-models/models/Place/converters'
import originalsConverter from '@somostera/tera-models/models/Originals/converters'
import journeyBlockConverter from '@somostera/tera-models/models/JourneyBlocks/JourneyBlockConverterFactory'
import getDocumentReference from '@/core/services/get-document-reference'

import { db, FieldPath, FieldValue } from '@/core/services/firebase'

export default {
  getAllByPerson: async uid => {
    if (!uid) {
      return []
    }

    const loggedPersonRef = getDocumentReference('people', uid)

    const journeyPeopleSnapshot = await db
      .collectionGroup('journey_people')
      .where('peopleRef', '==', loggedPersonRef)
      .get()

    if (journeyPeopleSnapshot.empty) return []

    const journeyTeraIds = journeyPeopleSnapshot.docs.map(
      doc => doc.data().teraId
    )

    var journeys = await getEntitiesByPropWithValues(
      'journeys',
      'teraId',
      journeyTeraIds,
      journeyConverter
    )

    if (journeys.length <= 0) {
      return []
    }

    return journeys
  },
  getJourneyPeopleByAuthId: async authId => {
    if (!authId) {
      return
    }

    const journeyPeopleQuerySnapshot = await db
      .collectionGroup('journey_people')
      .where('authId', '==', authId)
      .get()

    return journeyPeopleQuerySnapshot.docs.map(doc => {
      var data = doc.data()
      return { teraId: data.teraId, type: data.type }
    })
  },
  getAllJourneyBlocksByJourneyId: async journeyId => {
    if (!journeyId) {
      return
    }
    const journeyBlocksQuerySnapshot = await db
      .collection('journeys')
      .doc(journeyId)
      .collection('journey_blocks')
      .withConverter(journeyBlockConverter)
      .get()

    if (journeyBlocksQuerySnapshot.empty) return

    const journeyBlocks = journeyBlocksQuerySnapshot.docs.map(j => j.data())

    return journeyBlocks
  },
  async updateJourneyBlockDownloads(journeyId, journeyBlockId, material) {
    if (!journeyId) {
      return
    }
    await db
      .collection('journeys')
      .doc(journeyId)
      .collection('journey_blocks')
      .doc(journeyBlockId)
      .update({ downloads: FieldValue.arrayUnion(material) })
  },
  async deleteJourneyBlockSpecificDownload(
    journeyId,
    journeyBlockId,
    material
  ) {
    if (!journeyId) {
      return
    }
    await db
      .collection('journeys')
      .doc(journeyId)
      .collection('journey_blocks')
      .doc(journeyBlockId)
      .update({ downloads: FieldValue.arrayRemove(material) })
  },
  bindJourneyBlocksByJourneyId: (journeyId, updateCallback) => {
    if (!journeyId) {
      return
    }
    const observer = db
      .collection('journeys')
      .doc(journeyId)
      .collection('journey_blocks')
      .withConverter(journeyBlockConverter)
      .onSnapshot(function(querySnapshot) {
        var journeyBlocks = []
        querySnapshot.forEach(function(doc) {
          journeyBlocks.push(doc.data())
        })

        updateCallback(journeyBlocks)
      })

    return observer
  },
  getAllBasicJourneyBlocksByJourneyIds: async journeyIds => {
    if (!journeyIds || journeyIds.length === 0) {
      return []
    }

    const groupedByIdJourneyBlockPromises = journeyIds.map(async journeyId => {
      var journeyBlockById = {
        id: journeyId,
        journeyBlocks: []
      }

      const journeyBlocksQuerySnapshot = await db
        .collection('journeys')
        .doc(journeyId)
        .collection('journey_blocks')
        .withConverter(journeyBlockConverter)
        .get()

      if (journeyBlocksQuerySnapshot.empty) return journeyBlockById

      journeyBlockById.journeyBlocks = journeyBlocksQuerySnapshot.docs.map(j =>
        j.data()
      )

      return journeyBlockById
    })

    const groupedByIdJourneyBlock = await Promise.all(
      groupedByIdJourneyBlockPromises
    )

    return groupedByIdJourneyBlock
  },
  async getExpertAndPlaceDataFromJourneyBlocks(journeyBlocks) {
    var result = { experts: [], places: [] }

    var expertsIds = []
    var placesIds = []

    if (!journeyBlocks || journeyBlocks.length === 0) {
      return result
    }

    journeyBlocks.forEach(block => {
      if (block.type !== 'originals') {
        if (block.expertId) {
          expertsIds.push(block.expertId)
        }
        if (block.placeId) {
          placesIds.push(block.placeId)
        }
      }
    })

    result.experts = await getEntitiesByListId(
      'people',
      expertsIds,
      peopleConverter
    )
    result.places = await getEntitiesByListId(
      'places',
      placesIds,
      placeConverter
    )

    return result
  },
  async getAllOriginalsFromJourneyBlocks(journeyBlocks) {
    var result = []

    var originalsIds = []

    if (!journeyBlocks || journeyBlocks.length === 0) {
      return result
    }

    journeyBlocks.forEach(block => {
      if (block.type === 'originals' && block.originalsDocPath) {
        originalsIds.push(block.originalsDocPath.split('/')[1])
      }
    })

    result = await getEntitiesByListId(
      'originals',
      originalsIds,
      originalsConverter
    )

    return result
  },
  async getAllOriginalsFromJourneys(journeyIds) {
    var result = []

    var originalsIds = []

    if (!journeyIds || journeyIds.length === 0) {
      return result
    }

    for (var journeyId of journeyIds) {
      var querySnapshot = await db
        .collection('journeys')
        .doc(journeyId)
        .collection('journey_blocks')
        .where('type', '==', 'originals')
        .get()

      if (querySnapshot.empty) {
        continue
      }

      querySnapshot.docs.forEach(doc => {
        var block = doc.data()

        if (block.originalsRef.id) {
          originalsIds.push(block.originalsRef.id)
        }
      })
    }

    result = await getEntitiesByListId(
      'originals',
      originalsIds,
      originalsConverter
    )

    return result
  },
  isDpwAndJourneyEnded: currentUserJourney => {
    const { teraId, endsAt } = currentUserJourney

    return teraId.includes('EV_REM') && endsAt < new Date()
  }
}

const getEntitiesByListId = async (entity, ids, converter) => {
  let result = []

  if (!ids || ids.length === 0) {
    return result
  }

  const uniqueIds = Array.from(new Set(ids))

  var idsInChunksOfTen = []

  while (uniqueIds.length > 0) {
    idsInChunksOfTen.push(uniqueIds.splice(0, 10))
  }

  let queries = []

  queries = idsInChunksOfTen.map(ids => {
    let dbEntity = db
      .collection(entity)
      .where(FieldPath.documentId(), 'in', ids)
    if (converter) {
      dbEntity = dbEntity.withConverter(converter)
    }
    return dbEntity.get()
  })

  const querySnapshots = await Promise.all(queries)

  const resultsInChunksOfTen = querySnapshots.map(s =>
    s.docs.map(d => {
      return { ...d.data(), id: d.id }
    })
  )
  result = [].concat.apply([], resultsInChunksOfTen)
  return result
}

const getEntitiesByPropWithValues = async (
  collection,
  propName,
  propValues,
  converter
) => {
  let result = []

  if (!propValues || propValues.length === 0) {
    return result
  }

  const uniqueValues = Array.from(new Set(propValues))

  var valuesInChunksOfTen = []

  while (uniqueValues.length > 0) {
    valuesInChunksOfTen.push(uniqueValues.splice(0, 10))
  }

  let queries = []

  var dbEntity = db.collection(collection)

  if (converter) {
    dbEntity = dbEntity.withConverter(converter)
  }

  queries = valuesInChunksOfTen.map(values => {
    return dbEntity.where(propName, 'in', values).get()
  })

  const querySnapshots = await Promise.all(queries)

  const resultsInChunksOfTen = querySnapshots.map(s =>
    s.docs.map(d => {
      if (converter) {
        return d.data()
      } else {
        return { ...d.data(), id: d.id }
      }
    })
  )
  result = [].concat.apply([], resultsInChunksOfTen)
  return result
}
