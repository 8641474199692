import Vue from 'vue';
import App from './App.vue';
import router from '@/routes';
import store from '@/core/store';
// import './registerServiceWorker'
import { firebase } from '@/core/services/firebase';
import IconBase from '@/core/components/Icon/index.vue';
import Amplitude from '@/core/services/amplitude';
import DesignSystem from '@somostera/tera-design-system';
import '@/core/utils/webFontLoader';
import VueAnalytics from 'vue-analytics';
import VueMarkdown from 'vue-markdown';
import VueMasonry from 'vue-masonry-css';
import '@somostera/tera-design-system/dist/system/system.css';
import tokens from '@somostera/tera-design-system/dist/system/tokens/tokens.json';
import breadcrumb from '@/modules/Journeys/breadcrumb.js';
import trackevents from '@/modules/Journeys/trackevents';
import { sentryInit } from '@/core/config/sentry';
sentryInit();
Vue.use(Amplitude, {
    apiKey: process.env.VUE_APP_AMPLITUDE_API_KEY
});
Vue.use(VueAnalytics, {
    id: 'UA-77580812-5',
    router,
    autoTracking: {
        page: true,
        exception: true
    },
    debug: {
        sendHitTask: process.env.NODE_ENV === 'production' && document.location.host === 'app.somostera.com'
    }
});
Vue.component('vue-markdown', VueMarkdown);
Vue.component('icon-base', IconBase);
Vue.use(VueMasonry);
Vue.use(DesignSystem);
Vue.prototype.teraTokens = tokens;
Vue.prototype.$breadcrumbTemplates = { journeys: breadcrumb };
Vue.prototype.$trackeventsTemplates = { events: trackevents };
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    beforeCreate() {
        firebase
            .auth()
            .onAuthStateChanged(user => {
            localStorage.setItem('lxs-v2-is-login-with-google', JSON.stringify(false));
            this.$store.dispatch('account/toggleWaitingLogin', false);
            if (!user) {
                if (router.currentRoute.name !== 'Login') {
                    router.replace('/login');
                }
                this.$store.dispatch('account/setUser', null);
                this.$store.dispatch('account/setClaims', null);
                return;
            }
            firebase
                .auth()
                .currentUser.getIdToken()
                .then(token => {
                registerUserLastLogin(token);
            });
            this.$store.dispatch('account/setUser', JSON.parse(JSON.stringify(user)));
            firebase
                .auth()
                .currentUser.getIdTokenResult()
                .then(tokenResult => {
                this.$store.dispatch('account/setClaims', JSON.parse(JSON.stringify(tokenResult.claims)));
            })
                .catch(err => {
                console.error(err);
            });
        })
            .bind(this);
        firebase
            .auth()
            .getRedirectResult()
            .then(result => {
            if (result.user) {
                const event = {
                    eventId: trackevents.trackLogin.eventId,
                    portalId: trackevents.trackLogin.portalId,
                    email: result.user.email
                };
                this.$store.dispatch('events/sendEventEmail', event);
                router.replace('/');
            }
        })
            .catch(error => {
            alert('Parece que ocorreu algum erro com o seu processo de login. Por favor, tente novamente.');
            console.error(error);
        });
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Por favor, informe seu e-mail novamente para confirmarmos sua segurança');
            }
            if (email) {
                firebase
                    .auth()
                    .signInWithEmailLink(email, window.location.href)
                    .then(function (result) {
                    if (result.user) {
                        router.replace('/');
                    }
                })
                    .catch(function (error) {
                    alert('Parece que ocorreu algum erro com o seu processo de login. Por favor, tente novamente.');
                    console.error(error);
                });
            }
        }
    },
    render: h => h(App)
}).$mount('#app');
const registerUserLastLogin = (token) => {
    try {
        fetch(process.env.VUE_APP_LOG_LOGIN_URL, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                __session: token
            },
            referrerPolicy: 'no-referrer'
        });
    }
    catch (e) { }
};
