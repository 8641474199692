<template>
  <div class="paragraph-container">
    <div class="title">
      <heading tag="h4">{{ title }}</heading>
    </div>
    <div class="paragraph">
      <paragraph>{{ paragraph }}</paragraph>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParagraphWithTitle',
  props: {
    title: String,
    paragraph: String
  }
}
</script>

<style lang="scss" scoped>
.paragraph-container {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  margin: $space-xl 0;

  > .title {
    font-family: $font-rubik;
    margin-bottom: $space-xxs;
  }

  > .paragraph {
    font-family: $font-georgia;
    > p {
      line-height: $space-m;
    }
  }
}
</style>
