<template>
  <main class="main-container">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: 'MainContainer'
}
</script>

<style lang="scss" scoped>
.main-container {
  box-sizing: border-box;
  width: 100%;

  @media (max-width: $container-xl) {
    padding: 0 $space-l;
  }
}
</style>
