const trackEvent = async (data) => {
  try {
    await fetch('https://us-central1-tera-platform.cloudfunctions.net/url-tracking-code-event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  } catch (err) {
    console.error(err)
  }
}

export default trackEvent
