import Vue from 'vue';
import { NodeViewWrapper } from '@tiptap/vue-2';
import { mapActions } from 'vuex';
export default Vue.extend({
    name: 'PageBreak',
    components: {
        NodeViewWrapper
    },
    methods: Object.assign({}, mapActions('articles', ['articleToNextChapter']))
});
