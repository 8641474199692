import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

const axiosCustomInstance = axios.create()

axiosCustomInstance.defaults.headers.common.__session = localStorage.getItem(
  '_lxs_access_token'
)

axiosCustomInstance.interceptors.response.use(null, function(error) {
  if (error.config && error.response && error.response.status === 401) {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => {
        error.config.headers.__session = token
        localStorage.setItem('_lxs_access_token', token)
        return axios.request(error.config).then(() => {
          document.location.reload()
        })
      })
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }
})

export default axiosCustomInstance
