<template>
  <main class="sidebar-view">
    <article-chapter-sidebar v-if="currentJourneyArticle" :articleChapters="chapters" />
    <article class="article-container">
      <editor-view v-if="currentJourneyArticle && currentChapterIndex > -1" :content="currentChapter.content" />
      <div v-if="currentChapterIsLastChapter" class="button-container">
        <hr />
        <button type="button" @click="setLastChapter">Concluir</button>
      </div>
    </article>
  </main>
</template>

<script>
import ArticleChapterSidebar from '@/modules/Journeys/components/Article/ArticleChapterSidebar.vue'
import EditorView from '@/modules/Journeys/components/Article/EditorView.vue'

import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'Article',
  components: {
    ArticleChapterSidebar,
    EditorView
  },
  props: {
    teraId: String,
    articleId: String,
    chapterIndex: { type: Number, default: 0 }
  },
  computed: {
    chapters() {
      return this.currentJourneyArticle.chapters
    },
    currentChapter() {
      return this.currentJourneyArticle.chapters[this.currentChapterIndex]
    },
    ...mapGetters('articles', ['currentChapterIndex', 'currentJourneyArticle', 'currentChapterIsLastChapter'])
  },
  beforeMount() {
    this.setRouteBreadcrumb(null)
  },
  async mounted() {
    this.setCurrentTeraId(this.teraId)
    this.setCurrentArticleId(this.articleId)
  },
  methods: {
    setLastChapter() {
      this.articleToNextChapter()
      this.$router.go(-1)
    },
    ...mapActions('journeys', ['setCurrentTeraId', 'updateJourneyBlocksWithStream']),
    ...mapActions('articles', [
      'setArticleStateById',
      'setPeopleArticleInteractionState',
      'setCurrentArticleId',
      'articleToNextChapter'
    ]),
    ...mapActions('system', ['setRouteBreadcrumb']),
    ...mapMutations('articles', ['setCurrentChapter'])
  }
}
</script>

<style lang="scss">
.sidebar-view {
  min-height: 100%;

  .article-container {
    width: calc(100% - 260px);
    margin-left: 260px;
    padding-bottom: 32px;

    .button-container {
      display: flex;
      flex-direction: column;
      margin: 24px auto 32px;
      max-width: 560px;
      width: 100%;

      hr {
        border: 0;
        border-top: 2px solid #e0e0e0;
        border-bottom: none;
        width: 100%;
        margin-top: 0;
      }

      button {
        align-self: flex-end;
        background: #000;
        box-shadow: 0px 6px 12px rgba(51, 51, 51, 0.25);
        border: 0;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        font-family: Rubik;
        font-size: 16px;
        font-weight: 700;
        height: 48px;
        line-height: 20px;
        margin-top: 16px;
        padding: 8px;
        text-transform: uppercase;
        width: 146px;

        &:hover {
          transform: translate(1px, 1px);
        }
      }
    }
  }

  @media (max-width: 800px) {
    .article-container {
      margin: 0px;

      .button-container {
        display: flex;
        flex-direction: column;
        margin:  auto;
        padding: 0px 16px;
        max-width: 560px;
        width: auto;
        /* padding-bottom: 32px; */

        hr {
          border: 0;
          border-top: 2px solid #e0e0e0;
          border-bottom: none;
          width: 100%;
          margin-top: 0;
        }
    }
    }

    .editor .preview-container .ProseMirror h1 {
      margin-left: 0px;
      font-size: 24px;
      line-height: initial;
    }

    .editor .preview-container {
      padding: 0px 16px;
      padding-bottom: 32px;
      width: auto;
      top: 104px;
    }

    .ProseMirror{
      margin-top: 116px;
    }

  }
}
</style>
