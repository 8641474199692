<template>
  <div class="notification-tile">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'NotificationTile'
}
</script>

<style lang="scss" scoped>
.notification-tile {
  max-width: 368px;
  width: 100%;
  min-height: 80px;
  height: fit-content;
  padding: $space-s;
  background-color: $color-gray-100;
  box-sizing: border-box;
  z-index: 9;
}
</style>
