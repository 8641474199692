import { db } from '@/core/services/firebase'

export async function getAllCourses () {
  try {
    const snapshot = await db.collection('courses').get()
    return snapshot.docs.map((doc) => {
      return doc.data()
    })
  } catch (error) {
    console.error(error)
  }
}
