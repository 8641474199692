<template>
  <base-tab-menu
    type="div"
    class="journey-tab-menu"
    :active-tab="localActiveTab"
    :tab-menu-items="tabMenuItems"
    @input="localActiveTab = $event"
  >
  </base-tab-menu>
</template>

<script>
export default {
  name: 'JourneyTabMenu',
  status: 'prototype',
  release: '0.0.1',
  props: {
    journeyName: String,
    tabMenuItems: Array,
    activeTab: String
  },
  computed: {
    localActiveTab: {
      get() {
        return this.activeTab
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.journey-tab-menu {
  position: sticky !important;
  top: 120px;
  z-index: 8;
}
</style>
