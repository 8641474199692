<template>
  <modal
    class="modal-wrapper"
    :visible="visible"
    height="auto"
    width="100%"
    padding="0px"
    role="dialog"
    aria-labelledby="modalTitle"
    aria-describedby="modalDescription"
    @open-modal="openModal"
    @close-modal="closeModal"
  >
    <div class="modal-body">
      <base-button type="button"
                   class="btn-close"
                   @click="closeModal"
      >
        <icon name="ic_close_24" aria-label="Ícone Fechar Modal" />
      </base-button>
      <div class="modal-content">
        <div class="ic-gift">
          <icon name="icon_gift" aria-label="Ícone presente">
          </icon>
        </div>
        <div class="modal-description">
          <header id="modalTitle" class="title">
            <heading
              tag="h2"
              color="white"
            >
              Indique seus amigos e
              <em>ganhe até 100% de desconto</em>
              em seu próximo curso!
            </heading>
          </header>
          <section id="modalDescription" class="description">
            <paragraph
              color="gray-20"
            >
              As pessoas indicadas ganham descontos especiais no primeiro curso com a Tera. Você ganha descontos progressivos a cada inscrição :)
            </paragraph>
          </section>
        </div>
        <footer class="modal-footer">
          <div class="btn-white">
            <base-button
              type="a"
              href="https://rewards.somostera.com/?utm_campaign=Referral&utm_source=lxs&utm_content=botao-indique-aqui"
              fontColor="-font-black"
              color="-background-white"
              borderColor="-white"
              target="_blank"
              aria-label="Quero indicar"
              @click="sendEvent"
            >
              Quero indicar
            </base-button>
          </div>
        </footer>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ModalReferral',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    sendEvent() {
      const event = this.$trackeventsTemplates.events.trackButtonRefer
      this.setEvent(event)
    },
    openModal() {
      this.$emit('open-modal')
    },

    closeModal() {
      this.$emit('close-modal')
    },
    ...mapActions('events', ['setEvent'])
  }
}
</script>

<style lang='scss' scoped>

.modal-wrapper {
  margin: auto;
  position: fixed;
  left: 0 ;
  right: 0;
  height: 520px;
  width: 656px;
  top: calc(50% - 260px);

  @include breakpoint-small {
    width: 312px;
    top: calc(50% - 200px) !important;
  }

  > .modal-body {
    background: linear-gradient($color-black,$color-black) padding-box, linear-gradient(135deg, $color-green-20, $color-green-60) border-box;
    border: 6px solid transparent;
    min-width: 656px;
    box-sizing: border-box;

    @include breakpoint-small {
      min-width: 312px;
      min-height: 400px;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 112px 64px 112px;

      @include breakpoint-small {
        padding: 0px 24px 32px 24px;
      }

      .ic-gift {
        @include breakpoint-small {
          display:none;
        }
      }
    }

    .modal-description {
      position: relative;
      padding: 0px;
      text-align: center;
      max-width: 432px;
      display: inline-block;

      > .title {
        padding-top: $space-l;

        h2 {
          em {
              color: $color-blue-primary;
            @include breakpoint-small {
              max-width: 264px;
            }
          }
          @include breakpoint-small {
            max-width: 264px;
          }
        }

        @include breakpoint-small {
          padding-top:0px;
        }
      }

      > .description {
        padding-top: $space-s;
        padding-bottom:  $space-l;

        .paragraph {
            line-height: 24px;
            font-family: $font-rubik;
            font-weight: 400;
        }

        @include breakpoint-small {
          padding-bottom: $space-m;
        }
      }
    }
    .btn-close {
      display:flex;
      justify-content: flex-end;
      margin-left: auto;
      border: none;
      font-size: 20px;
      cursor: pointer;
      font-weight: bold;
      background: transparent;
      padding: $space-m ;

      @include breakpoint-small {
        border: none;
        font-size: 20px;
        padding: 24px 24px;
        cursor: pointer;
        font-weight: bold;
        background: transparent;
      }
    }

    .modal-footer {
      flex-direction: column;

      > .btn-white {
        a {
          padding: 14px 48px 14px 46px;
        }
      }
    }
  }

}

</style>
