var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modules"},[_vm._l((this.currentUserJourney.modules),function(module,moduleIndex){return _c('div',{key:moduleIndex + '_modules',staticClass:"module-container",attrs:{"id":'module-' + moduleIndex}},[_c('div',{staticClass:"text-container"},[_c('module-description',{key:moduleIndex,attrs:{"order":moduleIndex + 1,"title":module.name,"date":module.date,"visualization":"order","description":module.description}})],1),_c('div',{staticClass:"card-container"},[_vm._l((_vm.getJourneyBlocksByModuleIndex(moduleIndex)),function(jBlock,k){return (
          _vm.currentJourneyBlocksWithoutOriginals &&
            _vm.currentJourneyBlocksWithoutOriginals.length > 0 &&
            jBlock.type !== 'originals'
        )?_c('div',{key:k + '_journeyBlocks',staticClass:"card"},[_c('journey-block-card',{attrs:{"class-block":jBlock,"expert":_vm.getExpertById(jBlock.expertId),"place":_vm.getPlaceById(jBlock.placeId),"route-params":_vm.childRouteParams,"journey":_vm.currentUserJourney}})],1):_vm._e()}),(_vm.moduleHasOriginals(moduleIndex))?_c('div',{staticClass:"card"},[_c('card-router-link',{attrs:{"route":{
            name: 'ModuleLibrary',
            params: _vm.getModuleLibraryRouteParams(moduleIndex)
          }}},[_c('info-card',{attrs:{"image-url":"https://storage.googleapis.com/tera-lxs-images/illustration-biblioteca-módulo.svg","icon-name":"ic_slides_white","title":("Biblioteca: módulo " + (String(moduleIndex + 1).padStart(
              2,
              '0'
            ))),"subtitle":"Uma seleção de conteúdos para você ampliar seus conhecimentos nesse módulo."}})],1)],1):_vm._e()],2)])}),_c('div',{staticClass:"certification-container"},[(!_vm.isFinished)?_c('certification-card',{attrs:{"active":false,"image-url":_vm.certificationThumbnail,"image-description":"Certificado de participação oferecido pela Tera após o evento. Fundo preto, letras centralizadas ao canto esquerdo e brancas, com o nome da pessoa. Ao lado esquerdo existem diferentes formas geométricas e coloridas sobrepostas entre si","title":"Em breve seu certificado estará disponível.","subtitle":"Após o término dos encontros você poderá baixar seu certificado. Não se preocupe que lhe enviaremos um e-mail logo mais.","button-text":"Disponível em breve","button-state":"disabled","button-background-color":"-background-gray-20","button-font-color":"-font-gray-60"},on:{"click":function($event){return _vm.addEventCertificate()}}}):_c('certification-card',{attrs:{"active":true,"image-url":_vm.certificationThumbnail,"image-description":"Certificado de participação oferecido pela Tera após o evento. Fundo preto, letras centralizadas ao canto esquerdo e brancas, com o nome da pessoa. Ao lado esquerdo existem diferentes formas geométricas e coloridas sobrepostas entre si","title":"Baixe aqui seu certificado de participação.","subtitle":"Chegou a hora de mostrar para o mundo sua evolução profissional. Divulgue nas redes sociais ou inclua no seu Linkedin.","button-text":"Baixar certificado","button-background-color":"-background-black","button-font-color":"-font-white","certification-url":_vm.downloadCertificationUrl},on:{"click":function($event){return _vm.addEventCertificate()}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }