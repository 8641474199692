<template>
  <div class="navbar">
    <div class="content">
      <div class="logo">
        <router-link :to="{ name: 'Journeys' }">
          <img src="@/assets/tera_logo.svg" alt="Logo da Tera" />
        </router-link>
      </div>
      <div class="navbar-items">
        <ul>
          <li>
            <!-- <router-link :to="{ name: 'Journeys' }" exact>Cursos</router-link> -->
          </li>
        </ul>
      </div>
      <div v-show="activeButtonReferral" class="referral">
        <base-button
          color="-background-transparent"
          :class="{ black: activeButton }"
          @click="showModal"
        >
          Indique amigos
        </base-button>
      </div>
      <modal-referral
        v-if="modalIsOpen"
        :visible="modalIsOpen"
        @close-modal="closeModal"
      >
      </modal-referral>
      <div class="user">
        <span @click="showUserMenu">
          <img v-if="user" :src="user.photoURL" />
        </span>
        <transition name="fade-to-bottom">
          <div
            v-if="userMenuIsOpen"
            v-on-clickaway="hideUserMenu"
            class="user-menu"
          >
            <ul>
              <li @click="logout">Sair</li>
              <li>Tera - LXS v{{ version }}</li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { firebase } from '@/core/services/firebase'
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import ModalReferral from '@/modules/Referral/ModalReferral'

export default {
  name: 'Navbar',
  components: { ModalReferral },
  mixins: [clickaway],
  status: 'prototype',
  release: '0.0.1',
  data() {
    return {
      userMenuIsOpen: false,
      version: process.env.VUE_APP_VERSION,
      modalIsOpen: false
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('journeys', ['userJourneys']),
    activeButtonReferral() {
      return this.getJourneys()
    },
    activeButton() {
      return localStorage.getItem('button-referral-active') != null || this.$route.fullPath !== '/'
    }
  },
  methods: {
    logout() {
      firebase.auth().signOut()
      this.$store.dispatch('account/resetState')
    },
    showUserMenu() {
      this.userMenuIsOpen = true
    },
    hideUserMenu() {
      this.userMenuIsOpen = false
    },
    showModal() {
      localStorage.setItem('button-referral-active', true)
      this.modalIsOpen = true
    },
    closeModal() {
      this.modalIsOpen = false
    },
    findCommonJourneys(journeys, userJourneys) {
      return userJourneys.some(item => journeys.includes(item.teraId))
    },
    getJourneys() {
      const journeys = [
        'DTABC_REM_2021_2', 'TSTIN_SAO_2020_1', 'DPLBC_REM_2021_11', 'UXDBC_REM_2021_6', 'MKTBC_REM_2021_6', 'UXDBC_REM_2021_1', 'DPLBC_REM_2021_8', 'DPLIN_REM_2021_2', 'DPLIN_REM_2021_1', 'UXDBC_REM_2021_3', 'DPLIN_REM_2020_6', 'DPLBC_REM_2020_7', 'DPLIN_REM_2021_5', 'DLBIN_REM_2020_1', 'DPLBC_REM_2021_7', 'MKTBC_REM_2020_2', 'DPLBC_REM_2020_15', 'DPLBC_REM_2021_6', 'DPLBC_REM_2020_2', 'DPLBC_REM_2020_4', 'DPLIN_REM_2021_6', 'DPLIN_REM_2020_7', 'UXDBC_REM_2020_3', 'DPLIN_REM_2021_7', 'DPLBC_REM_2020_17', 'DPLIN_REM_2021_3', 'DPLBC_REM_2020_14', 'DPLIN_REM_2020_2', 'DPLBC_REM_2020_12', 'DLBIN_REM_2020_2', 'DPLIN_REM_2021_4', 'DLBBC_REM_2020_6', 'DPLBC_REM_2020_11', 'DPLBC_REM_2020_9', 'MKTBC_REM_2021_2', 'UXDBC_REM_2021_4', 'DSCBC_REM_2020_3', 'UXDBC_REM_2020_7', 'DLBBC_REM_2020_2', 'DLBBC_REM_2020_5', 'MKTBC_REM_2020_6', 'MKTIN_REM_2020_1'
      ]
      return this.findCommonJourneys(journeys, this.userJourneys)
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-to-bottom-enter-active,
.fade-to-bottom-leave-active {
  transition: all 0.2s ease;
}

.fade-to-bottom-enter,
.fade-to-bottom-leave-to {
  opacity: 0;
}

.fade-to-bottom-enter,
.fade-to-bottom-leave-to {
  transform: translateY(-15px);
}

.navbar {
  width: 100%;
  height: 60px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 10;
  position: sticky;
  top: 0;

  > .content {
    max-width: $container-xl;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .logo {
      margin: 10px;

      @media (min-width: $container_xl) {
        margin: 10px 10px 10px 0;
      }

      > .router-link-active {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }

    .navbar-items {
      flex-grow: 2;
      align-items: flex-end;
      justify-content: flex-end;
      display: inline-flex;

      ul {
        list-style: none;
        display: inline;
        margin: auto 0;

        @media (max-width: $container_s) {
          display: none;
        }

        li {
          font-size: 16px;
          font-family: 'Rubik', sans-serif;
          line-height: 20px;
          text-transform: uppercase;
          width: 140px;

          a {
            text-decoration: none;

            &.router-link-active {
              font-weight: bold;
            }

            &:active,
            &:focus,
            &:visited {
              color: $color-black;
            }
          }
        }
      }
    }
    .referral {
      display: inline-flex;
      padding: 10px;

      > button {
        font-weight: 700;
        color: $color-blue-primary;
        box-shadow: none;
      }

      .black {
        font-weight: 300;
        color: $color-black;
        box-shadow: none;
      }
    }

    .user {
      display: inline-flex;
      padding: 10px;

      @media (min-width: $container_xl) {
        padding: 10px 0 10px 10px;
      }

      img {
        border-radius: 100%;
        width: 38px;
        height: 38px;
        object-fit: cover;
        cursor: pointer;
      }

      .user-menu {
        background: #fff;
        box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
        min-width: 160px;
        position: absolute;
        margin-top: 40px;
        margin-left: -130px;
        z-index: 10;
        box-sizing: border-box;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            padding: 10px 15px;
            cursor: pointer;

            &:hover {
              background-color: $color-gray-20;
            }

            &:last-child {
              padding: 15px 15px 10px 15px;
              color: $color-gray-60;
              cursor: default;
              font-size: 12px;
              user-select: none;

              &:hover {
                background-color: initial;
              }

              &::before {
                content: '';
                height: 1px;
                width: 130px;
                margin-top: -15px;
                position: fixed;
                background: radial-gradient($color-gray-20, $color-white);
              }
            }
          }
        }
      }
    }
  }
}
</style>
