<template>
  <div class="modules">
    <div
      v-for="(module, moduleIndex) in this.currentUserJourney.modules"
      :id="'module-' + moduleIndex"
      :key="moduleIndex + '_modules'"
      class="module-container"
    >
      <div class="text-container">
        <module-description
          :key="moduleIndex"
          :order="moduleIndex + 1"
          :title="module.name"
          :date="module.date"
          visualization="order"
          :description="module.description"
        >
        </module-description>
      </div>
      <div class="card-container">
        <div
          v-for="(jBlock, k) in getJourneyBlocksByModuleIndex(moduleIndex)"
          v-if="
            currentJourneyBlocksWithoutOriginals &&
              currentJourneyBlocksWithoutOriginals.length > 0 &&
              jBlock.type !== 'originals'
          "
          :key="k + '_journeyBlocks'"
          class="card"
        >
          <journey-block-card
            :class-block="jBlock"
            :expert="getExpertById(jBlock.expertId)"
            :place="getPlaceById(jBlock.placeId)"
            :route-params="childRouteParams"
            :journey="currentUserJourney"
          ></journey-block-card>
        </div>
        <div v-if="moduleHasOriginals(moduleIndex)" class="card">
          <card-router-link
            :route="{
              name: 'ModuleLibrary',
              params: getModuleLibraryRouteParams(moduleIndex)
            }"
          >
            <info-card
              image-url="https://storage.googleapis.com/tera-lxs-images/illustration-biblioteca-módulo.svg"
              icon-name="ic_slides_white"
              :title="`Biblioteca: módulo ${String(moduleIndex + 1).padStart(
                2,
                '0'
              )}`"
              subtitle="Uma seleção de conteúdos para você ampliar seus conhecimentos nesse módulo."
            ></info-card>
          </card-router-link>
        </div>
      </div>
    </div>
    <div class="certification-container">
      <certification-card
        v-if="!isFinished"
        :active="false"
        :image-url="certificationThumbnail"
        image-description="Certificado de participação oferecido pela Tera após o evento. Fundo preto, letras centralizadas ao canto esquerdo e brancas, com o nome da pessoa. Ao lado esquerdo existem diferentes formas geométricas e coloridas sobrepostas entre si"
        title="Em breve seu certificado estará disponível."
        subtitle="Após o término dos encontros você poderá baixar seu certificado. Não se preocupe que lhe enviaremos um e-mail logo mais."
        button-text="Disponível em breve"
        button-state="disabled"
        button-background-color="-background-gray-20"
        button-font-color="-font-gray-60"
        @click="addEventCertificate()"
      >
      </certification-card>
      <certification-card
        v-else
        :active="true"
        :image-url="certificationThumbnail"
        image-description="Certificado de participação oferecido pela Tera após o evento. Fundo preto, letras centralizadas ao canto esquerdo e brancas, com o nome da pessoa. Ao lado esquerdo existem diferentes formas geométricas e coloridas sobrepostas entre si"
        title="Baixe aqui seu certificado de participação."
        subtitle="Chegou a hora de mostrar para o mundo sua evolução profissional. Divulgue nas redes sociais ou inclua no seu Linkedin."
        button-text="Baixar certificado"
        button-background-color="-background-black"
        button-font-color="-font-white"
        :certification-url="downloadCertificationUrl"
        @click="addEventCertificate()"
      >
      </certification-card>
    </div>
  </div>
</template>

<script>
import CardRouterLink from '@/core/components/CardRouterLink.vue'
import CertificationCard from '@/modules/Journeys/components/CertificationCard.vue'
import ModuleDescription from '@/modules/Journeys/components/ModuleDescription.vue'
import JourneyBlockCard from '@/modules/Journeys/components/ClassBlockCard.vue'

import Journey from '@somostera/tera-models/models/Journey'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ModuleVisualizationDPW',
  components: {
    JourneyBlockCard,
    ModuleDescription,
    CardRouterLink,
    CertificationCard
  },
  props: {
    currentUserJourney: Journey,
    currentJourneyBlocks: Array,
    currentJourneyExperts: Array,
    currentJourneyPlaces: Array,
    childRouteParams: Object
  },
  computed: {
    currentJourneyBlocksWithoutOriginals() {
      if (!this.currentJourneyBlocks) return []

      return this.currentJourneyBlocks
        .filter((jb) => jb.type !== 'originals')
        .sort((a, b) => (a.index > b.index ? 1 : -1))
    },
    isFinished() {
      if (!this.currentLastClassJourneyBlock) {
        return false
      }

      var lastJourneyBlockEndTime = new Date(
        this.currentLastClassJourneyBlock.endTime
      )

      const milisecondsOffset =
        lastJourneyBlockEndTime.getTimezoneOffset() * 60 * 1000

      lastJourneyBlockEndTime.setTime(
        lastJourneyBlockEndTime.getTime() + milisecondsOffset
      )

      return new Date() >= lastJourneyBlockEndTime
    },
    certificationThumbnail() {
      if (!this.currentUserJourney || !this.currentUserJourney.teraId) {
        return ''
      }

      if (this.currentUserJourney.teraId.includes('MKTEV')) {
        if (this.isFinished) {
          return 'https://storage.googleapis.com/tera-lxs-images/MKTEV_certification_imagem.jpg'
        }
        return 'https://storage.googleapis.com/tera-lxs-images/MKTEV_certification_imagem_bw.jpg'
      }

      if (this.isFinished) {
        return 'https://storage.googleapis.com/tera-lxs-images/DPW_certification_imagem.jpg'
      }

      return 'https://storage.googleapis.com/tera-lxs-images/DPW_certification_imagem_bw.jpg'
    },
    downloadCertificationUrl() {
      var personAuthId = this.user.uid
      return `https://us-central1-tera-platform.cloudfunctions.net/url-generate-event-certificate?a=${personAuthId}&t=${this.currentUserJourney.teraId}`
    },
    ...mapGetters('journeys', ['currentLastClassJourneyBlock']),
    ...mapGetters('account', ['user'])
  },
  methods: {
    getJourneyBlocksByModuleIndex(moduleIndex) {
      return this.currentJourneyBlocksWithoutOriginals
        .filter((journeyBlock, index) => {
          return journeyBlock.moduleIndex === moduleIndex
        })
        .sort((a, b) => (a.index > b.index ? 1 : -1))
    },
    getModuleLibraryRouteParams(moduleIndex) {
      return { ...this.childRouteParams, moduleIndex: moduleIndex + 1 }
    },
    moduleHasOriginals(moduleIndex) {
      return this.currentJourneyBlocks.some(
        (jb) => jb.moduleIndex === moduleIndex && jb.type === 'originals'
      )
    },
    getExpertById(id) {
      var expert = this.currentJourneyExperts.find((e) => e.id === id)
      return expert
    },
    getPlaceById(id) {
      var place = this.currentJourneyPlaces.find((p) => p.id === id)
      return place
    },
    addEventCertificate() {
      if (this.currentUserJourney.teraId.includes('MKTEV')) {
        const event = this.$trackeventsTemplates.events.trackCertificateEventMKT
        this.setEvent(event)
      } else if (this.currentUserJourney.teraId.includes('DPW')) {
        const event = this.$trackeventsTemplates.events.trackCertificateEventDPW
        this.setEvent(event)
      }
    },
    ...mapActions('events', ['setEvent'])
  }
}
</script>

<style lang="scss" scoped>
.module-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $space-xxl;
  margin-bottom: 64px;

  > .text-container {
    width: 100%;
    max-width: 752px;
    > .text {
      > .headings {
        display: flex;
      }
    }
  }

  > .card-container {
    display: flex;
    flex-direction: column;

    > .card {
      margin-bottom: 16px;
    }
  }
}

.modules {
  > .certification-container {
    display: flex;
    justify-content: center;
    margin-top: -64px;
  }
}
</style>
