<template>
  <card-router-link :route="goToRoute" class="originals-chapter-card">
    <!-- <a :href="this.originals.url" target="_blank"> -->
    <small-square-base-card
      class="originals-material-card"
      background-color="gray-100"
      text-color="none"
    >
      <template v-slot:top>
        <div class="image-container">
          <heading class="material-type" color="white" tag="h4">
            Tera Originals
          </heading>
          <img
            class="image"
            :src="originals.illustration"
            alt="Ilustração do originals"
            :class="{ 'no-image': imageDidNotLoad }"
            @error="imageLoadError"
            @load="imageLoadSuccess"
          />
        </div>
      </template>
      <template v-slot:content>
        <div class="content-container">
          <heading class="title" tag="h4" color="white" font-weight="normal">
            {{ originals.title }}
          </heading>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-container">
          <heading
            class="subtitle"
            color="gray-60"
            tag="h4"
            font-weight="normal"
            text-transform="none"
          >
            {{ 'Texto' }}
          </heading>
        </div>
      </template>
    </small-square-base-card>
  </card-router-link>
  <!-- </a> -->
</template>

<script>
import CardRouterLink from '@/core/components/CardRouterLink.vue'

export default {
  name: 'OriginalsMaterialCard',
  status: 'prototype',
  release: '0.0.1',
  components: {
    CardRouterLink
  },
  props: {
    /**
     * The object that represents a router link valid route, or an external link
     */
    routeParams: {
      type: Object,
      default: () => {
        return {}
      }
    },
    originals: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      imageDidNotLoad: false
    }
  },
  computed: {
    goToRoute() {
      return {
        name: 'JourneyOriginals',
        params: { originalsUrl: this.originals.url, ...this.routeParams }
      }
    }
  },
  methods: {
    imageLoadError() {
      this.imageDidNotLoad = true
    },
    imageLoadSuccess() {
      this.imageDidNotLoad = false
    }
  }
}
</script>

<style lang='scss' scoped>
.originals-material-card {
  width: 368px !important;
  max-height: 320px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  @include breakpoint-small {
    max-width: none !important;
    width: 100% !important;
  }

  > .slot-container {
    > .top {
      > .image-container {
        height: 200px;
        position: relative;
        background-color: $color-blue-primary;

        > .material-type {
          position: absolute;
          top: 18px;
          left: 26px;
          width: 15px;
        }

        > .image {
          width: auto;
          height: 100%;
          max-width: 144px;
          max-height: 144px;
          margin-top: 28px;
          margin-left: 50%;
          transform: translateX(-50%);

          &.no-image {
            display: none !important;
          }
        }
      }
    }

    > .content {
      > .content-container {
        height: 84px;
        padding: 12px 16px 0 16px;
      }
    }

    > .footer {
      > .footer-container {
        padding: 0px 16px 16px 16px;
      }
    }
  }
}
</style>
