<template>
  <router-link v-if="route.name" class="card-router-link" :to="route" tag="div">
    <slot></slot>
  </router-link>
  <a v-else class="card-router-link" :href="route.href" :target="route.target">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'CardRouterLink',
  props: {
    route: {
      type: Object,
      default: null,
      validator: (value) => {
        if (value && typeof value === 'object') {
          return !!value.name || !!value.href
        }

        return value == null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-router-link {
  width: fit-content;
  &:hover {
    cursor: pointer;
  }

  @include breakpoint-small {
    width: 100%;
  }
}
</style>
