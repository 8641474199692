<template>
  <router-link :to="route" class="onboarding-card">
    <div>
      <div class="image-container">
        <img :src="imageUrl" :alt="imageDescription" />
      </div>
      <div class="content-container">
        <paragraph
          type="span"
          class="span"
          color="black"
          text-transform="uppercase"
          font-weight="bold"
          font-family="rubik"
        >
          {{ span }}
        </paragraph>
        <heading class="title" color="black" tag="h3" font-weight="normal">
          {{ title }}
        </heading>
      </div>
      <div class="footer-container">
        <heading
          class="subtitle"
          tag="h4"
          color="black"
          font-weight="normal"
          text-transform="none"
        >
          {{ subtitle }}
        </heading>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'OnboardingCard',
  status: 'prototype',
  release: '0.0.1',
  props: {
    /**
     * The object that represents a router link valid route, or an external link
     */
    route: {
      type: Object,
      default: null,
      validator: (value) => {
        if (value && typeof value === 'object') {
          return !!(value.name || value.href)
        }

        return value == null
      }
    },
    /**
     * A Title for this component, required
     */
    title: {
      type: String,
      default: '',
      required: true,
      validator: (value) => {
        return value !== ''
      }
    },
    /**
     * A SubTitle for this component
     */
    subtitle: {
      type: String,
      default: ''
    },
    /**
     * A span for this component
     */
    span: {
      type: String,
      default: ''
    },
    /**
     * Use this prop to add image url
     */
    imageUrl: {
      type: String,
      default: ''
    },
    /**
     * Use this prop to add image description for alt
     */
    imageDescription: {
      type: String,
      default: 'Imagem do card'
    }
  }
}
</script>

<style lang='scss' scoped>
.onboarding-card {
  width: 100%;
  max-width: 464px;
  height: auto;
  min-height: 455px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-family: $font-rubik;

  > div {
    > .image-container {
      min-height: 336px;

      > img {
        width: 100%;
        height: fit-content;

        @include breakpoint-small {
          width: 100%;
          height: 100%;
        }
      }
      @include breakpoint-small {
        min-height: unset;
      }
    }

    > .content-container {
      height: 44px;
      margin: 12px 0 4px 0;

      @include breakpoint-small {
        margin-top: 0;
      }
    }
  }
  @include breakpoint-small {
    min-height: unset;
    margin-bottom: 32px;
  }
}
</style>

<docs>
  ```jsx
  <OnboardingCard
  span='Comece por aqui'
  title='Primeiros Passos'
  subtitle='#básico #boasvindas #entenda #primeirospassos'
  imageUrl='https://storage.googleapis.com/tera-lxs-images/primeiros-passos-illustration.svg'
  ></OnboardingCard>

  ```
</docs>
