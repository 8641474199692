<template>
  <aside class="sidebar-container">
    <nav class="sidebar">
      <slot></slot>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'Sidebar'
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 260px;
  height: 100%;
  background-color: #333333;
}
</style>
