<template>
  <div class="vertical-progress">
    <div
      v-for="(item, i) in items"
      :key="'vertical-progress-status-key-' + i"
      :class="['status', item.status]"
      @click="clickItem(i)"
    >
      <div class="badge">
        <icon-base
          v-if="item.status === 'done'"
          name="check"
          color="#99fb8c"
          size="18"
        />
        <div v-if="item.status === 'doing'" class="circle"></div>
      </div>
      <heading
        :class="['status-name', item.currentChapter ? 'current' : '']"
        tag="h5"
        :font-weight="item.currentChapter ? 'bold' : 'normal'"
      >
        {{ item.current }}
        {{ item.name }}
      </heading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalProgress',
  props: {
    items: Array
  },
  methods: {
    clickItem(index) {
      this.$emit('onChangeCurrentItem', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-progress {
  width: 100%;
  padding: 16px 24px 0;
  box-sizing: border-box;

  > .status {
    width: 100%;
    border-left: 2px solid #999999;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;
    color: #999999;

    > .status-name {
      font-weight: normal;
      line-height: 12px;
      padding-bottom: 16px;
      padding-top: 4px;

      &.current {
        color: #fff;

        > .badge {
          background-color: #99fb8c;
        }
      }
    }

    &.done {
      cursor: pointer;
      border-left: 2px solid #99fb8c;

      svg {
        background: #333;
        border-radius: 50%;
      }

      > .badge {
        background-color: #99fb8c;
        border: 2px solid #99fb8c;
      }
    }

    &.doing {
      cursor: pointer;

      > .badge {
        align-items: center;
        border: 2px solid #99fb8c;
        display: flex;
        justify-content: center;

        > .circle {
          background-color: #99fb8c;
          border-radius: 50%;
          height: 6.4px;
          width: 6.4px;
        }
      }
    }

    &:last-child {
      border-color: transparent !important;
      border-image: none;
    }

    > h4 {
      margin: 0;
    }

    > .badge {
      background-color: #333;
      border: 2px solid #bbb;
      border-radius: 100%;
      height: 14px;
      left: 0;
      position: absolute;
      top: 0%;
      transform: translateX(calc(-50% - 0.5px));
      width: 14px;

      svg {
        left: -2px;
        position: absolute;
        top: -2px;
      }
    }
  }
}

  @media(max-width: 800px) {

    .vertical-progress {
      display: flex;
      padding-left: 0px;

      > .status {
        width: 32px;
        border-left: 0px;
        border-top: 2px solid;
        height: 20px;

        &.done {
          border-left: 0px;
          border-top: 2px solid #99fb8c;
        }

        &.doing {
          border-left: 0px;

          &:last-child {
            border-color: transparent !important;
            border-image: none;
          }
        }

      > .badge {
        transform: translateY(calc(-50% - 0.5px));
        background-color: #000;
      }

      }
    }

    .status-name {
      visibility: hidden;
      height: 0px;
      padding: 0px;
      font-size: 0px;
    }
  }
</style>
