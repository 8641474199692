<template>
  <transition :name="`fade-${transitionName}`">
    <slot v-if="isVisible" ref="slot"></slot>
    <div v-else ref="background" key="loading" class="loading-background">
      <loading v-if="showLoadingAnimation"></loading>
    </div>
  </transition>
</template>

<script>
import Loading from '@/core/components/Loading'

export default {
  name: 'DebounceLoad',
  components: {
    Loading
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    intervalTime: {
      type: Number,
      default: 100
    },
    transitionName: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      showLoadingAnimation: false,
      debounceInterval: null
    }
  },
  computed: {
    isVisible() {
      if (this.debounceInterval === null) {
        return false
      } else if (this.debounceInterval) {
        return false
      } else {
        return !this.isLoading
      }
    }
  },
  watch: {
    isLoading() {
      this.debounceLoad()
    }
  },
  mounted() {
    this.debounceLoad()
  },
  methods: {
    debounceLoad() {
      this.debounceInterval = setInterval(() => {
        this.showLoadingAnimation = this.isLoading

        clearInterval(this.debounceInterval)
        this.debounceInterval = undefined
      }, this.intervalTime)
    }
  }
}
</script>

<style lang="scss">
.fade-default-enter-active,
.fade-default-leave-active {
  transition: opacity 0.3s;
}

.fade-default-enter-to,
.fade-default-leave {
  opacity: 1;
}

.fade-default-enter,
.fade-default-leave-to {
  opacity: 0;
}

.loading-background {
  background: $color-white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  border: 0px solid transparent;
  z-index: 10;

  * {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
