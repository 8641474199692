<template>
  <div id="topo" class="list-all">
    <div class="header">
      <base-header background-color="blue-primary">
        <heading-with-before color="white" :before-color-variation="true">
          {{ title }}
        </heading-with-before>
        <heading color="white" tag="h4">{{ subtitle }}</heading>
      </base-header>
    </div>
    <div class="main">
      <div class="cards">
        <onboarding-card
          v-for="(i, index) in onboardingContent"
          :key="index"
          :image-url="i.imageUrl"
          :image-description="i.imageDescription"
          :span="i.span"
          :title="i.title"
          :subtitle="i.subtitle"
          :route="i.route"
        ></onboarding-card>
      </div>
      <!-- <div class="button">
      <base-button color="-background-transparent" borderColor="-border-gray-80" fontColor="-font-gray-80" href="/#topo">Voltar p/ topo</base-button>
    </div> -->
    </div>
  </div>
</template>

<script>
import OnboardingCard from '@/modules/Journeys/components/JourneyManual/OnboardingCard'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ListAll',
  components: {
    // BaseHeader
    OnboardingCard
  },
  data() {
    return {
      title: 'Manual do Curso',
      subtitle: 'Módulo Zero',
      onboardingContent: [
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/primeiros-passos-illustration.svg',
          imageDescription: '',
          span: 'Comece por aqui',
          title: 'Primeiros Passos',
          subtitle: '#básico #boasvindas #entenda #primeirospassos',
          route: {
            name: 'journey-manual-intro'
            // params: {
            //   courseId: this.courseId
            // }
          }
        },
        // {
        //   imageUrl: 'https://storage.googleapis.com/tera-lxs-images/networking-illustration.svg',
        //   imageDescription: '',
        //   span: 'Networking',
        //   title: 'Sua Turma',
        //   subtitle: '#pessoas #contatos #coordenação #facilitação #bio',
        //   route: {
        //     name: 'journey-manual-networking'
        //     // params: {
        //     //   courseId: this.courseId
        //     // }
        //   }
        // },
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/teraway-illustration.svg',
          imageDescription: '',
          span: 'Nosso Jeito',
          title: 'Teraway',
          subtitle: '#jornada #dna #humano #regras #visao #caminho',
          route: {
            name: 'journey-manual-teraway'
            // params: {
            //   courseId: this.courseId
            // }
          }
        },
        {
          imageUrl:
            'https://storage.googleapis.com/tera-lxs-images/ferramentas-illustration.svg',
          imageDescription: '',
          span: 'Para você usar',
          title: 'Ferramentas',
          subtitle: '#ferramentas #framework #biblioteca #referencia #projeto',
          route: {
            name: 'journey-manual-toolkit'
            // params: {
            //   courseId: this.courseId
            // }
          }
        }
        // {
        //   imageUrl: 'https://storage.googleapis.com/tera-lxs-images/project-illustration.svg',
        //   imageDescription: '',
        //   span: 'Aprendizado',
        //   title: 'Projeto',
        //   subtitle: '#briefing #Aprendizado #entregas #real #teamwork',
        //   route: {
        //     name: 'journey-manual-project'
        //     // params: {
        //     //   courseId: this.courseId
        //     // }
        //   }
        // },
        // {
        //   imageUrl: 'https://storage.googleapis.com/tera-lxs-images/saibamais-illustration.svg',
        //   imageDescription: '',
        //   span: 'Saiba mais',
        //   title: 'Informações Gerais',
        //   subtitle: '# conversa #locais #FAQ #info #timetera #respostas',
        //   route: {
        //     name: 'journey-manual-infos'
        //     // params: {
        //     //   courseId: this.courseId
        //     // }
        //   }
        // },
        // {
        //   imageUrl: 'https://storage.googleapis.com/tera-lxs-images/valores-illustration.svg',
        //   imageDescription: '',
        //   span: 'Nosso Coração',
        //   title: 'Valores e Mindsets',
        //   subtitle: '# conversa #locais #FAQ #info #timetera #respostas',
        //   route: {
        //     name: 'journey-manual-infos'
        //     // params: {
        //     //   courseId: this.courseId
        //     // }
        //   }
        // }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('journeys', ['currentJourneyMoment'])
  },
  beforeMount() {
    const {
      journeys,
      modules,
      journeyManuals
    } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules, journeyManuals]

    this.setRouteBreadcrumb(breadcrumb)
  },
  mounted() {
    this.$amplitude.getInstance().logEvent('manual seen', {
      section: 'index',
      'tera id': this.courseId,
      user: this.user ? this.user.uid : '',
      moment: this.currentJourneyMoment,
      value: 'low'
    })

    const id = new this.$amplitude.Identify()
    id.set('current moment', this.currentJourneyMoment)
    this.$amplitude.getInstance().identify(id)
  },
  methods: {
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.list-all {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .header {
    width: 100%;
    > .base-header {
      ::v-deep .container {
        max-width: 944px;
      }
    }
  }

  > .main {
    > .cards {
      width: 100%;
      max-width: $container-l;
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0 208px 0;
      // margin-top: 40px;
      // margin-bottom: 208px;

      > a:first-of-type {
        margin-right: 16px;
        @include breakpoint-small {
          margin-right: unset;
        }
      }
    }

    > .button {
      width: 100%;
      max-width: $container-l;
      margin-top: 56px;

      > .button {
        width: 220px;
        left: 0;
      }
    }
    @include breakpoint-small {
      max-width: 300px;
    }
  }
}
</style>
