import { render, staticRenderFns } from "./NotificationTile.vue?vue&type=template&id=cff8a816&scoped=true&"
import script from "./NotificationTile.vue?vue&type=script&lang=js&"
export * from "./NotificationTile.vue?vue&type=script&lang=js&"
import style0 from "./NotificationTile.vue?vue&type=style&index=0&id=cff8a816&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff8a816",
  null
  
)

export default component.exports