<template>
  <card-router-link :route="routeToGo">
    <rectangular-card backgroundColor="color_black" class="short-card">
      <template v-slot:left>
        <div class="icon-container">
          <progress-bar v-if="progressPercentage < 99" color="#99FB8C" size="32" :percent="progressPercentage" />
          <div v-else class="circle">
            <icon-base name="check" size="32" color="#99FB8C" />
          </div>
        </div>
      </template>
      <template v-if="article" v-slot:content>
        <div class="content-container">
          <heading class="title" tag="h3">{{ article.name }}</heading>
          <heading class="subtitle" fontWeight="normal" tag="h4" textTransform="none">
            {{ readingTime }} min
            <span v-if="numberOfVideos && numberOfVideos === 1"> • {{ numberOfVideos }} vídeo </span>
            <span v-else-if="numberOfVideos"> • {{ numberOfVideos }} vídeos </span>
          </heading>
        </div>
      </template>
    </rectangular-card>
  </card-router-link>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import CardRouterLink from '@/core/components/CardRouterLink'
import ProgressBar from '../components/ProgressBar'
import { getReadingTime, getNumberOfVideos, getProgressPercentage, getDurationOfVideos } from '../utils/tiptapUtils'

export default {
  name: 'ArticleCard',
  components: {
    CardRouterLink,
    ProgressBar
  },
  props: {
    teraId: String,
    articleId: String
  },
  data() {
    return {
      article: null,
      doneChapters: 0
    }
  },
  computed: {
    routeToGo() {
      return {
        name: 'journey-article',
        params: {
          teraId: this.teraId,
          articleId: this.articleId
        }
      }
    },
    numberOfVideos() {
      return this.article ? getNumberOfVideos(this.article.chapters) : 0
    },
    readingTime() {
      return this.article ? getReadingTime(this.article.chapters) : 0
    },
    progressPercentage() {
      if (this.article && this.article.chapters) {
        return getProgressPercentage(this.article.chapters.length, this.doneChapters)
      }

      return 0
    },
    ...mapState('articles', ['currentJourneyArticles']),
    ...mapGetters('articles', ['getArticleById'])
  },
  watch: {
    currentJourneyArticles() {
      if (this.currentJourneyArticles.length > 0) {
        this.article = this.getArticleById(this.articleId)
      }
    }
  },
  async mounted() {
    if (this.currentJourneyArticles.length > 0) {
      this.article = this.getArticleById(this.articleId)
    }

    if (this.articleId) {
      this.doneChapters = await this.getNumberOfChaptersDone(this.articleId)
    }
  },
  methods: {
    ...mapActions('articles', ['getNumberOfChaptersDone'])
  }
}
</script>

<style lang="scss" scoped>
@include breakpoint-small {
  v::deep .responsive-image-container {
    object-fit: none;
  }
}

.short-card {
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 112px !important;
  padding-bottom: 16px !important;

  @include breakpoint-small {
    display: block;
    object-fit: cover;
  }

  > .slot-container {
    > .aside-left {
      width: 80px;

      > .icon-container {
        align-items: center;
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        padding: 16px 24px;
      }
    }

    > .content {
      > .content-container {
        padding-top: 16px;
        box-sizing: inherit;

        @include breakpoint-small {
          padding-left: $space-xxs;
        }

        > .subtitle {
          margin-top: 8px;
          color: #999999;
        }
      }
    }
  }
}

@media(max-width: 800px) {
  .short-card {
    max-height: 160px;
  }
}

</style>
