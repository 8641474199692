<template>
  <a
    href="https://somostera.com/cursos/digital-marketing?utm_campaign=digital_marketing_essentials&utm_source=lxs&utm_content=banner-dme&c=dme_lxs"
    target="_blank"
    aria-label="Banner Marketing ( Abre uma nova janela )"
    alt="Link para site externo de cursos da Tera"
  >
    <div class="mkt-ad" @click="sendEvent">
      <img alt="Figuras geométricas das cores cinza, azul e verde."
           class="mobile element"
           src="https://storage.googleapis.com/tera-lxs-images/banner_mkt_desc_mobile.svg"
      />
      <div class="large medium element left">
        <img src="https://storage.googleapis.com/tera-lxs-images/banner_mkt_desc_01_desktop.svg"
             alt="No canto esquerdo um semi-círculo da cor cinza. No meio, uma forma geométrica da cor azul claro na parte superior e na inferior duas setas cinzas apontando para baixo. No canto direito, uma figura geométrica da cor verde claro."
        />
      </div>
      <div class="coupom">
        <div class="wrapper">
          <p>condições especiais para você continuar se especializando!</p>
          <heading tag="h2">R$ 1.000,00</heading>
          <heading tag="h3" color="white">
            de desconto para o curso Digital Marketing da Tera.
          </heading>
          <p class="coupom-text">utilize o cupom <span>#DME_LXS</span></p>
        </div>
      </div>
      <div class="large medium element right">
        <img alt="Figuras geométricas das cores cinza, azul claro e verde."
             src="https://storage.googleapis.com/tera-lxs-images/banner_mkt_desc_02_desktop.svg"
        />
      </div>

      <img
        class="tablet element"
        alt="Figuras geométricas das cores cinza, azul e verde."
        src="https://storage.googleapis.com/tera-lxs-images/banner_mkt_desc_tablet.svg"
      />
    </div>
  </a>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'MktAd',
  methods: {
    sendEvent() {
      const event = this.$trackeventsTemplates.events.trackBannerMkt
      this.setEvent(event)
    },
    ...mapActions('events', ['setEvent'])
  }
}
</script>

<style lang='scss' scoped>
a {
  text-decoration: none;
}

.mkt-ad {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $color-black;
  width: calc(100% - 3px);
  margin: 16px 0 0;
  position: relative;

  @include breakpoint-medium {
    min-height: 250px;
    height: 100%;
    width: 100%;
  }

  @include breakpoint-small {
    height: 100%;
    width: 100%;
  }

  .coupom {
    flex-grow: 2;

    .wrapper {
      max-width: 444px;
      color: $color-white;
      font-family: 'Rubik';
      text-transform: uppercase;
      margin: 0 0 0 39px;

      @include breakpoint-small {
        margin: 24px;
      }

      @include breakpoint-medium {
        margin: 24px!important;
        max-width: 376px;
      }

      @include breakpoint-large {
        height: 100%;
        margin: 24px;
        margin-left: auto;
        max-width: 376px;
      }

      p {
        margin: 36px 0 3px;
        font-size: 16px;
        line-height: 20px;
        font-weight: lighter;

        @include breakpoint-small {
          margin: 136px 0 3px;
          max-width: 100%;
        }

        &.course-name {
            margin-top:3px;
            max-width: 376px;
        }

        &.coupom-text {
          margin: 0 0 36px 0;
          font-weight: bold;
          span {
            color: $color-red;
          }

          @include breakpoint-small {
            margin: 0 0 24px 0;
            max-width: 100%;
          }
        }
      }

      h2 {
        color: $color-blue-primary;
        font-size: 32px;
        line-height: 32px;
        margin: 16px 0 8px 0;

        @include breakpoint-small {
          margin: 12px 0px 8px 0;
        }
      }

      h3 {
        font-size: 24px;
        line-height: 24px;
        margin: 0 0 16px;

        @include breakpoint-small {
          margin: 0 0 16px;
          max-width: 100%;
        }
      }

      small {
        font-size: 16px;
        line-height: 20px;
        text-transform: none;
        color: $color-gray-40;

        @include breakpoint-small {
          margin: 0 0 24px 0;
          display: block;
        }
      }
    }
  }

  .mobile {
    display: none;

    @include breakpoint-small {
      padding-top:25px;
      display: block;
      position: absolute;
      width:100%;
    }
  }

   .tablet {
    display: none;

    @include breakpoint-large {
      display: block;
    }
    @include breakpoint-small {
     display: none;
    }
  }

  .element {
    &.left {
      @include breakpoint-small {
        display: none;
      }

      img {
        margin: 0;
        width: 277px;
        height: 250px;
        @include breakpoint-medium {
          display:none;
        }
        @include breakpoint-large {
          display: none;
        }
        @include breakpoint-small {
          display: none;
        }
      }
    }

    &.right {
      @include breakpoint-medium {
        display: none;
      }

      @include breakpoint-small {
        display: none;
      }

      @include breakpoint-large {
        display: none;
      }

      img {
        margin: 0px;
        width: 372px;
        height: 250px;
        @include breakpoint-medium {
          margin: 21px 0 0 0;
          display: none;
        }
        @include breakpoint-small {
          display: none;
        }
      }
    }
  }
}
</style>
