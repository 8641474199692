import trackEvent from '@/core/api/hubspot.api'

const actions = {
  async setEvent({ rootState }, data) {
    const { user } = rootState.account
    let email = null

    if (data.email) {
      email = data.email
    } else if (user.email) {
      email = user.email
    }
    const payload = {
      eventId: data.eventId,
      portalId: data.portalId,
      email: email
    }

    try {
      await trackEvent(payload)
    } catch (error) {
      console.log(error)
    }
  },
  async sendEventEmail({ dispatch }, data) {
    await dispatch('setEvent', data)
  }
}

export const events = {
  namespaced: true,
  actions
}
