export default {
  journeys: {
    routeName: 'Journeys',
    displayName: 'Cursos'
  },
  modules: {
    routeName: 'Modules',
    displayName: '{store:journeys/currentUserJourney.courseInfo.name}',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}'
    }
  },
  journeyClass: {
    routeName: 'Class',
    displayName: '{store:journeys/currentJourneyBlock.name}',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}',
      journeyBlockId: '{store:journeys/currentJourneyBlockId}'
    }
  },
  moduleLibrary: {
    routeName: 'ModuleLibrary',
    displayName: 'Biblioteca: Módulo {store:journeys/currentModuleIndexPadded}',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}',
      moduleIndex: '{store:journeys/currentModuleIndex}'
    }
  },
  originals: {
    routeName: 'Originals',
    displayName: '{store:journeys/currentOriginals.title}',
    urlParams: {
      originalsUrl: '{store:journeys/currentOriginalsUrl}'
    }
  },
  journeyOriginals: {
    routeName: 'JourneyOriginals',
    displayName: '{store:journeys/currentOriginals.title}',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}',
      moduleIndex: '{store:journeys/currentModuleIndex}',
      originalsUrl: '{store:journeys/currentOriginalsUrl}'
    }
  },
  originalsChapter: {
    routeName: 'OriginalsChapter',
    displayName: '{store:journeys/currentOriginalsChapterObject.title}',
    urlParams: {
      originalsUrl: '{store:journeys/currentOriginalsUrl}',
      chapter: '{store:journeys/currentOriginalsChapter}'
    }
  },
  journeyOriginalsChapter: {
    routeName: 'OriginalsChapter',
    displayName: '{store:journeys/currentOriginalsChapterObject.title}',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}',
      moduleIndex: '{store:journeys/currentModuleIndex}',
      originalsUrl: '{store:journeys/currentOriginalsUrl}',
      chapter: '{store:journeys/currentOriginalsChapter}'
    }
  },
  journeyManuals: {
    routeName: 'journey-manuals',
    displayName: 'Manual do curso',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}'
    }
  },
  journeyManualIntro: {
    routeName: 'journey-manual-intro',
    displayName: 'Primeiros passos',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}'
    }
  },
  journeyManualTeraway: {
    routeName: 'journey-manual-teraway',
    displayName: 'Teraway',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}'
    }
  },
  journeyManualToolkit: {
    routeName: 'journey-manual-toolkit',
    displayName: 'Ferramentas',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}'
    }
  },
  journeyArticle: {
    routeName: 'journey-article',
    displayName: 'Artigo',
    urlParams: {
      teraId: '{store:journeys/currentTeraId}',
      articleId: '{store:articles/currentArticleId}'
    }
  }
}
