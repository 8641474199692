<template>
  <router-link v-slot="{ navigate }" :to="goToRoute">
    <!-- <router-link :to="goToRoute" tag="div"> -->
    <div
      @click="
        (e) => {
          checkExternalRoute(e, navigate)
        }
      "
    >
      <small-square-base-card :class="[journeyCardStyle]">
        <template v-slot:top>
          <div class="icon-container">
            <icon class="icon" :name="iconName" aria-label="Icone do curso" />
          </div>
        </template>
        <template v-slot:content>
          <div class="content-container">
            <progress-bar
              v-if="showProgress()"
              :progress-number="completionPercentage"
            ></progress-bar>
            <p v-else-if="!showProgress() && !isDPW" class="finished-text">
              Concluído
            </p>
            <heading
              class="title"
              tag="h2"
              :color="currentCourse ? 'white' : 'black'"
              font-weight="normal"
            >
              {{ title }}
            </heading>
          </div>
        </template>
        <template v-slot:footer>
          <div class="footer-container">
            <heading
              v-if="journey.name"
              class="subtitle"
              :color="currentCourse ? 'white' : 'black'"
              tag="h4"
              font-weight="normal"
              text-transform="none"
            >
              {{ journey.name }}
            </heading>
          </div>
        </template>
      </small-square-base-card>
    </div>
  </router-link>
</template>

<script>
import Journey from '@somostera/tera-models/models/Journey'

import journeyService from '@/modules/Journeys/services/journeysService'

export default {
  name: 'JourneyCard',
  status: 'prototype',
  release: '0.0.1',
  props: {
    /**
     * The object that represents a router link valid route, or an external link
     */
    route: {
      type: Object,
      default: null,
      validator: (value) => {
        if (value && typeof value === 'object') {
          return !!(value.name || value.href)
        }

        return value == null
      }
    },
    /**
     * Use this prop to change general behaviour of component
     */
    currentCourse: {
      type: Boolean,
      default: true
    },
    journey: {
      type: Journey,
      default: () => {
        return new Journey()
      },
      required: true
    }
  },
  computed: {
    goToRoute() {
      if (this.currentCourse) {
        return {
          name: 'Modules',
          params: {
            teraId: this.journey.teraId
          }
        }
      } else {
        return {
          path: this.route.href
        }
      }
    },
    isDPW() {
      if (!this.journey.teraId) {
        return false
      }

      return this.journey.teraId.includes('EV_REM')
    },
    completionPercentage() {
      if (!this.journey.startsAt || !this.journey.endsAt) {
        return 0
      }

      const startDate = this.journey.startsAt
      const endDate = this.journey.endsAt

      const startDateUTC = new Date(
        Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate(),
          0,
          0,
          0
        )
      )

      if (startDateUTC > new Date()) {
        return 0
      }

      const endDateUTC = new Date(
        Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate(),
          23,
          59,
          59
        )
      )

      const totalTimeRange = endDateUTC - startDateUTC
      const remainingPeriod = endDateUTC - new Date()

      const percentCompleted = Math.abs(
        (remainingPeriod / totalTimeRange) * 100 - 100
      )

      return percentCompleted > 100 ? 100 : percentCompleted
    },
    title() {
      const { courseInfo } = this.journey

      return courseInfo ? courseInfo.name : ''
    },
    iconName() {
      const { courseInfo } = this.journey

      if (this.isDPW) {
        return `${courseInfo.acronym}ev_gradient`
      }

      return courseInfo ? courseInfo.acronym + '_gradient' : 'dpl_gradient'
    },
    journeyCardStyle() {
      if (
        this.journey.teraId &&
        journeyService.isDpwAndJourneyEnded(this.journey)
      ) {
        return 'journey-card'
      } else if (this.isFinished) {
        return 'journey-card finished-journey-card'
      } else if (this.currentCourse) {
        return 'journey-card'
      } else {
        return 'journey-card -text-color-black -background-gray-20 -other-courses'
      }
    },
    isFinished() {
      return this.completionPercentage >= 100
    }
  },
  methods: {
    checkExternalRoute(event, navigate) {
      if (this.route && this.route.href) {
        return window.open(this.route.href, '_blank')
      }
      return navigate(event)
    },
    showProgress() {
      const journeyisDPW = this.isDPW

      if (journeyisDPW) {
        return false
      }

      const journeyIsNotFinished = !this.isFinished

      return journeyIsNotFinished
    }
  }
}
</script>

<style lang="scss" scoped>
.journey-card {
  max-width: 368px !important;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @include breakpoint-medium {
    max-width: 100% !important;
    height: 368px !important;
  }

  &.finished-journey-card {
    background-color: $color-gray-100 !important;

    .finished-text {
      font-family: Rubik;
      font-size: 16px;
      height: 16px;
      width: 90px;
      text-transform: uppercase;
      background: $color-green-to-blue;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 6px;
    }
  }

  > .slot-container {
    > .top {
      > .icon-container {
        margin: 32px 0 0 32px;
      }
    }
    > .content,
    .footer {
      > .content-container,
      .footer-container {
        margin-left: 24px;
      }
    }

    > .content {
      > .content-container {
        > .progress-bar {
          margin-bottom: 16px;
        }

        > h2 {
          min-height: 65px;
        }

        @include breakpoint-small {
          max-width: 265px;
          padding-top: 0;
        }
      }
    }

    > .footer {
      > .footer-container {
        margin: 8px 24px 24px;
      }
    }
  }

  &.-inactive {
    opacity: 0.75;
  }
}

.-other-courses {
  > .slot-container {
    > .content {
      > .content-container {
        > .progress-bar {
          opacity: 0;
        }
      }
    }
  }
}
</style>
