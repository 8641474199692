<template>
  <node-view-wrapper class="video-wrapper">
    <vue-plyr :options="options">
      <video width="100%" controls playsinline :src="node.attrs.src"></video>
    </vue-plyr>
  </node-view-wrapper>
</template>

<script>
import Vue from 'vue'
import VuePlyr from 'vue-plyr'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import 'vue-plyr/dist/vue-plyr.css'

export default Vue.extend({
  name: 'Video',
  components: {
    NodeViewWrapper,
    VuePlyr
  },
  props: nodeViewProps,
  data() {
    return {
      options: {
        controls: [
          'play', // Play/pause playback
          'progress', // The progress bar and scrubber for playback and buffering
          'duration', // The full duration of the media
          'mute', // Toggle mute
          'volume', // Volume control
          'captions', // Toggle captions
          'settings', // Settings menu
          'pip', // Picture-in-picture (currently Safari only)
          'fullscreen' // Toggle fullscreen
        ],
        speed: { selected: 1, options: [0.25, 0.5, 1, 1.25, 1.5, 2] }
      }
    }
  }
})
</script>
