var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modules"},[_c('div',{staticClass:"module-container",attrs:{"id":"module-0"}},[_c('div',{staticClass:"text-container"},[_c('module-description',{attrs:{"order":0,"title":"Módulo Zero"}})],1),_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card"},[_c('card-router-link',{attrs:{"route":{ name: 'journey-manuals' }}},[_c('info-card',{attrs:{"active":true,"image-url":"https://storage.googleapis.com/tera-lxs-images/onboarding-card.jpg","title":"Onboarding","subtitle":"Entenda tudo o que você precisa para se preparar para o curso e começe com o pé direito"}})],1)],1)])]),_vm._l((_vm.currentUserJourney.modules),function(module,moduleIndex){return _c('div',{key:moduleIndex + '_modules',staticClass:"module-container",attrs:{"id":'module-' + (moduleIndex + 1)}},[_c('div',{staticClass:"text-container"},[_c('module-description',{key:moduleIndex,attrs:{"order":moduleIndex + 1,"title":module.name,"paragraph":"Teste"}})],1),_c('div',{staticClass:"card-container"},[_vm._l((_vm.getJourneyBlocksByModuleIndex(moduleIndex)),function(jBlock,k){return (
          _vm.currentJourneyBlocksWithoutOriginals &&
          _vm.currentJourneyBlocksWithoutOriginals.length > 0 &&
          jBlock.type !== 'originals'
        )?_c('div',{key:k + '_journeyBlocks',staticClass:"card"},[(jBlock.type === 'article')?_c('article-card',{attrs:{"teraId":_vm.childRouteParams.teraId,"articleId":jBlock.articlesRef.id}}):_c('journey-block-card',{attrs:{"journey":_vm.currentUserJourney,"class-block":jBlock,"expert":_vm.getExpertById(jBlock.expertId),"place":_vm.getPlaceById(jBlock.placeId),"route-params":_vm.childRouteParams}})],1):_vm._e()}),(_vm.moduleHasOriginals(moduleIndex))?_c('div',{staticClass:"card"},[_c('card-router-link',{attrs:{"route":{
            name: 'ModuleLibrary',
            params: _vm.getModuleLibraryRouteParams(moduleIndex)
          }}},[_c('info-card',{attrs:{"image-url":"https://storage.googleapis.com/tera-lxs-images/illustration-biblioteca-módulo.svg","icon-name":"ic_slides_white","title":("Biblioteca: módulo " + (String(moduleIndex + 1).padStart(
              2,
              '0'
            ))),"subtitle":"Uma seleção de conteúdos para você ampliar seus conhecimentos nesse módulo."}})],1)],1):_vm._e()],2)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }