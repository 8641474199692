import accountApi from '@/core/api/account.api'

const state = {
  user: null,
  claims: [],
  person: null,
  isWaitingLogin: false
}

const getters = {
  person: state => {
    return state.person
  },
  user: state => {
    return state.user
  },
  isWaitingLogin: state => {
    return state.isWaitingLogin
  },
  isLoggedIn: state => {
    if (state.user && state.person) {
      return true
    } else {
      return false
    }
  }
}

const actions = {
  async setUser({ commit, dispatch }, user) {
    commit('setUser', user)
    if (!user || !user.uid) return
    await dispatch('setPerson', user.uid)
  },
  async setPerson({ commit }, uid) {
    const person = await accountApi.getPerson(uid)
    commit('setPerson', JSON.parse(JSON.stringify(person)))
  },
  setClaims({ commit }, claims) {
    commit('setClaims', claims)
  },
  toggleWaitingLogin({ commit }, isWaitingLogin) {
    commit('setIsWaitingLogin', isWaitingLogin)
  },
  resetState({ commit }) {
    commit('resetState')
  }
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setClaims(state, claims) {
    state.claims = claims
  },
  setPerson(state, person) {
    state.person = person
  },
  setIsWaitingLogin(state, isWaitingLogin) {
    state.isWaitingLogin = isWaitingLogin
  },
  resetState(state) {
    state.person = null
    state.user = null
    state.claims = []
  }
}

export const account = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
