import journeyService from '@/modules/Journeys/services/journeysService'
import Journey from '@somostera/tera-models/models/Journey'
import ClassBlock from '@somostera/tera-models/models/JourneyBlocks/ClassBlock'

import storageApi from '@/core/api/storage.api'

const INITIAL_ONSNAPSHOT_VALUE = -1
const RESETED_ONSNAPSHOT_VALUE = 0

export const getInitialState = () => {
  return {
    userJourneys: [],
    currentJourneyBlocks: [],
    currentUserOriginals: [],
    currentJourneyArticles: [],
    currentJourneyExperts: [],
    currentJourneyPlaces: [],
    currentUserJourneyPeople: [],
    currentJourneyArticlesData: [],
    isLoading: false,

    originalsIsLoading: false,
    originalsLoadingDebounce: null,

    currentTeraId: '',
    currentModuleIndex: null,
    currentJourneyBlockId: '',
    currentOriginalsUrl: '',
    currentOriginalsChapter: null,

    streamedJourneyBlocksUpdates: INITIAL_ONSNAPSHOT_VALUE,
    streamedJourneyBlocks: [],
    streamedJourneyBlocksUnsubscribe: null
  }
}

const state = getInitialState()

const getters = {
  userJourneys: state => {
    return state.userJourneys.sort((a, b) => {
      return b.endsAt - a.endsAt
    })
  },
  currentUserJourney: state => {
    if (state.userJourneys.length > 0) {
      const journey = state.userJourneys.find(
        j => j.teraId === state.currentTeraId
      )
      if (journey) {
        return journey
      }
    }
    return new Journey('', {}, '', new Date(), new Date())
  },
  currentJourneyBlocks: state => {
    return state.currentJourneyBlocks
  },
  currentLastClassJourneyBlock: state => {
    var jbSize = state.currentJourneyBlocks.length
    if (!jbSize) {
      return null
    }

    var lastJourneyBlockIndex = jbSize - 1

    while (lastJourneyBlockIndex > 0) {
      const journeyBlock = state.currentJourneyBlocks.find(
        jb => jb.index === lastJourneyBlockIndex
      )

      if (journeyBlock.type === 'originals') {
        lastJourneyBlockIndex -= 1
      } else {
        return journeyBlock
      }
    }
  },
  currentUserOriginals: state => {
    return state.currentUserOriginals
  },
  currentJourneyArticles: state => {
    return state.currentJourneyArticles
  },
  currentJourneyArticlesData: state => {
    return state.currentJourneyArticlesData
  },
  currentOriginals: state => {
    if (state.currentUserOriginals.length > 0) {
      const originals = state.currentUserOriginals.find(
        to => to.url === state.currentOriginalsUrl
      )
      return originals
    }
    return null
  },
  currentOriginalsChapter: state => {
    return state.currentOriginalsChapter
  },
  currentOriginalsUrl: state => {
    return state.currentOriginalsUrl
  },
  currentOriginalsChapterObject: state => {
    if (state.currentUserOriginals.length > 0) {
      const originals = state.currentUserOriginals.find(
        to => to.url === state.currentOriginalsUrl
      )
      const chapter = originals
        ? originals.chapters[state.currentOriginalsChapter - 1]
        : null
      return chapter
    }
    return null
  },
  currentJourneyBlock: state => {
    if (state.currentJourneyBlocks.length > 0) {
      const journeyBlock = state.currentJourneyBlocks.find(
        jb => jb.id === state.currentJourneyBlockId
      )
      if (journeyBlock) {
        return journeyBlock
      }
    }
    return new ClassBlock('', {}, '', new Date(), new Date())
  },
  currentJourneyPeople: state => {
    const { currentTeraId } = state
    if (currentTeraId) {
      return state.currentUserJourneyPeople.find(
        t => t.teraId === currentTeraId
      )
    }
    return null
  },
  isLoading: state => {
    return state.isLoading
  },
  originalsIsLoading: state => {
    return state.originalsIsLoading
  },
  journeyBlocksHasUpdates: state => {
    return state.streamedJourneyBlocksUpdates > 0
  },
  currentExpert: (state, getters) => {
    const { currentJourneyBlock } = getters
    if (currentJourneyBlock) {
      return state.currentJourneyExperts.find(
        e => e.id === currentJourneyBlock.expertId
      )
    }
  },
  currentPlace: (state, getters) => {
    const { currentJourneyBlock } = getters
    if (currentJourneyBlock) {
      return state.currentJourneyPlaces.find(
        p => p.id === currentJourneyBlock.placeId
      )
    }
  },
  currentJourneyExperts: state => {
    return state.currentJourneyExperts
  },
  currentJourneyPlaces: state => {
    return state.currentJourneyPlaces
  },
  currentTeraId: state => {
    return state.currentTeraId
  },
  currentJourneyBlockId: state => {
    return state.currentJourneyBlockId
  },
  currentModuleIndex: state => {
    return state.currentModuleIndex
  },
  currentModuleIndexPadded: state => {
    if (state.currentModuleIndex) {
      return String(state.currentModuleIndex).padStart(2, '0')
    }
    return null
  },
  currentJourneyMoment: (state, getters) => {
    const { currentUserJourney } = getters

    if (!currentUserJourney) return ''

    const courseStartDate = currentUserJourney.startsAt
    const courseEndDate = currentUserJourney.endsAt
    const today = new Date()

    if (today < courseStartDate) {
      return 'pre-journey'
    } else if (today > courseEndDate) {
      return 'post-journey'
    } else {
      return 'during-journey'
    }
  }
}

const actions = {
  async uploadDownloadableContent({ state, getters }, { file, name }) {
    try {
      var url = await storageApi.uploadContent(file)
      const material = {
        name: name,
        url: url,
        type: file.name.substring(file.name.lastIndexOf('.') + 1).toUpperCase(),
        sizeInMb: (file.size / 1024 / 1024).toFixed(2)
      }
      journeyService.updateJourneyBlockDownloads(
        getters.currentUserJourney.id,
        state.currentJourneyBlockId,
        material
      )
    } catch (error) {
      console.log(error)
    }
  },
  async deleteSpecificDownloadableContent({ state, getters }, material) {
    try {
      await journeyService.deleteJourneyBlockSpecificDownload(
        getters.currentUserJourney.id,
        state.currentJourneyBlockId,
        material
      )
    } catch (error) {
      console.log(error)
    }
  },
  async getAllByPersonRef({ commit, state, rootState, getters, dispatch }) {
    const { user } = rootState.account
    if (!user || !user.uid) {
      return
    }

    commit('toggleLoading', true)

    try {
      const journeys = await journeyService.getAllByPerson(user.uid)

      commit('setUserJourneys', journeys)

      const journeyPeople = await journeyService.getJourneyPeopleByAuthId(
        user.uid
      )

      commit('setCurrentUserJourneyPeople', journeyPeople)

      commit('toggleOriginalsIsLoading', true)
      var currentOriginals = await journeyService.getAllOriginalsFromJourneys(
        journeys.map(j => {
          return j.id
        })
      )
      commit('setUserOriginals', currentOriginals)
      commit('toggleOriginalsIsLoading', false)

      if (state.currentTeraId) {
        await dispatch('setCurrentJourneyBlocks')
      } else {
        commit('toggleLoading', false)
      }
    } catch (e) {
      commit('toggleLoading', false)
    }
  },
  async setCurrentTeraId({ commit, state, getters, dispatch }, teraId) {
    if (state.currentTeraId === teraId) {
      return
    }

    commit('setCurrentTeraId', teraId)

    await dispatch('setCurrentJourneyBlocks')
  },
  setCurrentModuleIndex({ commit }, moduleIndex) {
    commit('setCurrentModuleIndex', moduleIndex)
  },
  setCurrentOriginalsUrl({ commit }, originalsUrl) {
    commit('setCurrentOriginalsUrl', originalsUrl)
  },
  setCurrentOriginalsChapter({ commit }, originalsChapter) {
    commit('setCurrentOriginalsChapter', originalsChapter)
  },
  setCurrentJourneyBlockId({ commit, state }, journeyBlockId) {
    commit('setCurrentJourneyBlockId', journeyBlockId)
  },
  toggleLoading({ commit, state }, isLoading) {
    commit('toggleLoading', isLoading)
  },
  async setCurrentJourneyBlocks({ commit, state, getters, dispatch }) {
    if (getters.currentUserJourney.id) {
      commit('toggleLoading', true)

      dispatch('unbindStreamedJourneyBlocks')

      dispatch('bindStreamedJourneyBlocksByJourneyId')

      const currentUserJourney = getters.currentUserJourney

      const journeyBlocks = await journeyService.getAllJourneyBlocksByJourneyId(
        currentUserJourney.id
      )

      commit('setCurrentJourneyBlocks', journeyBlocks)

      const {
        experts,
        places
      } = await journeyService.getExpertAndPlaceDataFromJourneyBlocks(
        journeyBlocks
      )

      commit('setCurrentJourneyExperts', experts)
      commit('setCurrentJourneyPlaces', places)

      commit('toggleLoading', false)
    }
  },
  clearCurrentJourneyStates({ commit, state }) {
    commit('clearCurrentJourneyStates')
  },
  clearState({ commit, state }) {
    commit('clearState')
  },
  bindStreamedJourneyBlocksByJourneyId({ commit, state, getters }) {
    const currentJourney = getters.currentUserJourney
    if (currentJourney.id === '') {
      return
    }

    const unsubscribe = journeyService.bindJourneyBlocksByJourneyId(
      currentJourney.id,
      journeyBlocks => {
        commit('updateStreamedJourneyBlocks', journeyBlocks)
      }
    )

    commit('setStreamedJourneyBlocksUnsubscribe', unsubscribe)
  },
  unbindStreamedJourneyBlocks({ commit, state, getters }) {
    commit('unbindStreamedJourneyBlocks')
  },
  updateJourneyBlocksWithStream({ commit, state }) {
    if (state.streamedJourneyBlocks && state.streamedJourneyBlocksUpdates > 0) {
      commit('toggleLoading', true)
      const loadingInterval = setInterval(() => {
        commit('setCurrentJourneyBlocks', state.streamedJourneyBlocks)
        commit('clearStreamedJourneyBlocksUpdates')
        commit('toggleLoading', false)
        clearInterval(loadingInterval)
      }, 300)
    }
  }
}

const mutations = {
  setUserJourneys(state, userJourneys) {
    state.userJourneys = userJourneys
  },
  setCurrentUserJourneyPeople(state, journeyPeople) {
    state.currentUserJourneyPeople = journeyPeople
  },
  setCurrentJourneyExperts(state, experts) {
    state.currentJourneyExperts = experts
  },
  setCurrentJourneyPlaces(state, places) {
    state.currentJourneyPlaces = places
  },
  setCurrentJourneyBlocks(state, journeyBlocks) {
    state.currentJourneyBlocks = journeyBlocks
  },
  setUserOriginals(state, originals) {
    state.currentUserOriginals = originals
  },
  setCurrentTeraId(state, teraId) {
    state.currentTeraId = teraId
  },
  setCurrentModuleIndex(state, moduleIndex) {
    state.currentModuleIndex = moduleIndex
  },
  setCurrentOriginalsUrl(state, originalsUrl) {
    state.currentOriginalsUrl = originalsUrl
  },
  setCurrentOriginalsChapter(state, originalsChapter) {
    state.currentOriginalsChapter = originalsChapter
  },
  setCurrentJourneyBlockId(state, journeyBlockId) {
    state.currentJourneyBlockId = journeyBlockId
  },
  toggleLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  toggleOriginalsIsLoading(state, originalsIsLoading) {
    if (originalsIsLoading) {
      state.originalsLoadingDebounce = debounce(() => {
        state.originalsIsLoading = originalsIsLoading
      })
    } else {
      clearInterval(state.originalsLoadingDebounce)
      state.originalsIsLoading = originalsIsLoading
    }
  },
  updateStreamedJourneyBlocks(state, journeyBlocks) {
    state.streamedJourneyBlocks = journeyBlocks
    state.streamedJourneyBlocksUpdates++
  },
  clearStreamedJourneyBlocksUpdates(state) {
    state.streamedJourneyBlocksUpdates = RESETED_ONSNAPSHOT_VALUE
  },
  unbindStreamedJourneyBlocks(state) {
    if (state.streamedJourneyBlocksUnsubscribe) {
      state.streamedJourneyBlocksUnsubscribe()
    }
    state.streamedJourneyBlocksUnsubscribe = getInitialState().streamedJourneyBlocksUnsubscribe
    state.streamedJourneyBlocksUpdates = getInitialState().streamedJourneyBlocksUpdates
  },
  setStreamedJourneyBlocksUnsubscribe(state, unsubscribe) {
    state.streamedJourneyBlocksUnsubscribe = unsubscribe
  },
  clearCurrentJourneyStates(state) {
    Object.assign(state, {
      ...getInitialState(),
      userJourneys: state.userJourneys
    })
  },
  clearState(state) {
    Object.assign(state, getInitialState())
  }
}

export const journeys = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const debounce = callback => {
  return setInterval(() => {
    callback()
  }, 1500)
}

export const journeysWatchers = {
  getUserJourneysWithAccountPerson(store) {
    return {
      state: state => state.account.user,
      watcher: user => {
        if (user && user.uid) {
          if (store.getters['system/routeContext'] === 'journeys') {
            store.dispatch('journeys/getAllByPersonRef')
          }
        } else {
          store.dispatch('journeys/clearState')
        }
      }
    }
  },
  getUserJourneysWhenContextChange(store) {
    return {
      state: state => state.system.routeContext,
      watcher: routeContext => {
        const user = store.state.account.user
        if (routeContext === 'journeys' && user && user.uid) {
          store.dispatch('journeys/getAllByPersonRef')
        } else {
          store.dispatch('journeys/clearState')
        }
      }
    }
  },
  setLoadingStateWhenJourneyIsSystemContext(store) {
    return {
      state: state => state.system.routeContext,
      watcher: routeContext => {
        const user = store.state.account.user
        if (routeContext === 'journeys' && user) {
          store.dispatch('journeys/toggleLoading', true)
        } else {
          store.dispatch('journeys/toggleLoading', false)
        }
      }
    }
  }
}
