import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import Component from './Component.vue';
export default Node.create({
    name: 'pageBreak',
    group: 'block',
    atom: true,
    addCommands() {
        return {
            addPageBreak: () => ({ commands }) => commands.insertContent('<page-break></page-break>')
        };
    },
    parseHTML() {
        return [{ tag: 'page-break' }];
    },
    renderHTML() {
        return ['page-break'];
    },
    addNodeView() {
        return VueNodeViewRenderer(Component);
    }
});
