<template>
  <transition name="back-from-the-ashes">
    <notification-tile
      v-if="journeyBlocksHasUpdates"
      class="journey-update-notification-tile"
    >
      <heading
        class="title"
        tag="h4"
        color="white"
        font-weight="normal"
        text-transform="none"
      >
        Uma nova versão dessa página está disponível.
      </heading>
      <base-button
        class="button"
        color="-background-blue-primary"
        @click="updateJourneyBlocksWithStream"
      >
        Atualizar
      </base-button>
    </notification-tile>
  </transition>
</template>

<script>
import NotificationTile from '@/core/components/NotificationTile'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'JourneyUpdateNotificationTile',
  components: {
    NotificationTile
  },
  computed: {
    ...mapGetters('journeys', ['journeyBlocksHasUpdates'])
  },
  methods: {
    ...mapActions('journeys', ['updateJourneyBlocksWithStream'])
  }
}
</script>

<style lang="scss" scoped>
.back-from-the-ashes-enter-active,
.back-from-the-ashes-leave-active {
  transition: opacity 0.8s ease-in-out, bottom 0.8s ease-in-out;
}
.back-from-the-ashes-enter, .back-from-the-ashes-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  bottom: -1000px;
}

.journey-update-notification-tile {
  display: flex;
  > .title {
    height: 100%;
    margin-right: $space-s;
  }

  > .button {
    height: fit-content;
    width: 120px;
  }
}
</style>
