<template>
  <div class="modules-content">
    <div class="header">
      <journey-header
        :course-name="courseName"
        :journey-name="journeyName"
        :course-icon="courseIcon"
      ></journey-header>
    </div>
    <journey-tab-menu
      :tab-menu-items="tabMenuItems"
      :active-tab="activeTab"
      @input="changeActiveTab($event)"
    ></journey-tab-menu>
    <main-container>
      <module-visualization-dpw
        v-if="journeyIsDpw"
        :current-user-journey="currentUserJourney"
        :current-journey-blocks="currentJourneyBlocks"
        :current-journey-experts="currentJourneyExperts"
        :current-journey-places="currentJourneyPlaces"
        :child-route-params="childRouteParams"
      >
      </module-visualization-dpw>
      <module-visualization-default
        v-else
        :current-user-journey="currentUserJourney"
        :current-journey-blocks="currentJourneyBlocks"
        :current-journey-experts="currentJourneyExperts"
        :current-journey-places="currentJourneyPlaces"
        :child-route-params="childRouteParams"
      >
      </module-visualization-default>
    </main-container>
  </div>
</template>

<script>
import MainContainer from '@/core/components/MainContainer'
import JourneyHeader from '@/modules/Journeys/components/JourneyHeader.vue'
import JourneyTabMenu from '@/modules/Journeys/components/JourneyTabMenu.vue'
import ModuleVisualizationDefault from '@/modules/Journeys/components/ModuleVisualization/Default.vue'
import ModuleVisualizationDpw from '@/modules/Journeys/components/ModuleVisualization/DPW.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Modules',
  components: {
    MainContainer,
    JourneyHeader,
    JourneyTabMenu,
    ModuleVisualizationDefault,
    ModuleVisualizationDpw
  },
  props: {
    teraId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: '0'
    }
  },
  computed: {
    courseName() {
      const { courseInfo } = this.currentUserJourney

      return courseInfo && courseInfo.name !== undefined ? courseInfo.name : ''
    },
    journeyName() {
      return this.currentUserJourney.name
    },
    courseIcon() {
      const { courseInfo } = this.currentUserJourney

      if (this.journeyIsDpw) {
        return {
          url: `https://storage.googleapis.com/tera-lxs-images/icons/icon_${courseInfo.acronym}ev_gradient.svg`,
          alt: `Ícone do curso: ${courseInfo.name}`
        }
      }

      return {
        url: `https://storage.googleapis.com/tera-lxs-images/icons/icon_${courseInfo.acronym}_gradient.svg`,
        alt: `Ícone do curso: ${courseInfo.name}`
      }
    },
    childRouteParams() {
      return { teraId: this.teraId }
    },
    tabMenuItems() {
      var modules = []

      modules = this.currentUserJourney.modules.map((m, index) => {
        const moduleNumber = index + 1
        const moduleName = ('0' + moduleNumber).slice(-2)
        return {
          name: moduleName,
          component: !this.journeyIsDpw ? String(moduleNumber) : String(index)
        }
      })

      if (!this.journeyIsDpw) {
        modules.splice(0, 0, {
          name: '00',
          component: String(0)
        })
      }

      return modules
    },
    journeyIsDpw() {
      return this.currentUserJourney.teraId.includes('EV_REM')
    },
    ...mapGetters('journeys', [
      'isLoading',
      'currentUserJourney',
      'currentJourneyBlocks',
      'journeyBlocksHasUpdates',
      'currentJourneyExperts',
      'currentJourneyPlaces'
    ]),
    ...mapGetters('account', ['person', 'user'])
  },
  beforeMount() {
    this.setCurrentTeraId(this.teraId)
    this.enableScrollspyForActiveTabs()
    const { journeys, modules } = this.$breadcrumbTemplates.journeys

    const breadcrumb = [journeys, modules]

    this.setRouteBreadcrumb(breadcrumb)
  },
  mounted() {
    this.$amplitude.getInstance().logEvent('modules seen', {
      'tera id': this.teraId,
      user: this.user ? this.user.uid : '',
      moment: this.currentJourneyMoment,
      value: 'low'
    })

    const id = new this.$amplitude.Identify()
    id.set('current moment', this.currentJourneyMoment)
    this.$amplitude.getInstance().identify(id)
  },
  destroyed() {
    this.disableScrollspyForActiveTabs()
  },
  methods: {
    bindCourses() {
      this.$store.dispatch('getJourneys')
    },
    enableScrollspyForActiveTabs() {
      window.addEventListener('scroll', this.changeActiveTabOnScroll, {
        passive: true
      })
    },
    disableScrollspyForActiveTabs() {
      window.removeEventListener('scroll', this.changeActiveTabOnScroll)
    },
    changeActiveTabOnScroll() {
      const modules = document.querySelectorAll('.module-container')
      modules.forEach((el, index) => {
        const elTop = el.getBoundingClientRect().top - 190
        const elBottom = el.getBoundingClientRect().bottom
        if (elTop <= 0 && elBottom >= 0) {
          this.activeTab = String(index)
        }
      })
    },
    changeActiveTab(value) {
      var tab = this.$el.querySelector('#module-' + value)

      window.scrollTo(0, tab.getBoundingClientRect().y - 190 + window.scrollY)
    },
    ...mapActions('journeys', [
      'setCurrentTeraId',
      'setCurrentJourneyBlockId',
      'updateJourneyBlocksWithStream'
    ]),
    ...mapActions('system', ['setRouteBreadcrumb'])
  }
}
</script>

<style lang="scss" scoped>
.modules-content {
  width: 100%;
  box-sizing: border-box;

  > .main-container {
    margin: 0 auto;

    @include breakpoint-medium {
      max-width: 752px;
    }
  }
}
</style>
